$XS-size: 320px

$breakpoint-xs-up: "(min-width: #{$XS-size})"
$breakpoint-sm-up: "(min-width: #{map-get($grid-breakpoints, sm)})"
$breakpoint-md-up: "(min-width: #{map-get($grid-breakpoints, md)})"
$breakpoint-lg-up: "(min-width: #{map-get($grid-breakpoints, lg)})"
$breakpoint-xl-up: "(min-width: #{map-get($grid-breakpoints, xl)})"

$breakpoint-xs-down: "(max-width: #{$XS-size - 0.02})"
$breakpoint-sm-down: "(max-width: #{map-get($grid-breakpoints, sm) - 0.02})"
$breakpoint-md-down: "(max-width: #{map-get($grid-breakpoints, md) - 0.02})"
$breakpoint-lg-down: "(max-width: #{map-get($grid-breakpoints, lg) - 0.02})"
$breakpoint-xl-down: "(max-width: #{map-get($grid-breakpoints, xl) - 0.02})"

$breakpoint-xxs-only: "(max-width: #{$XS-size - 0.02})"
$breakpoint-xs-only: "(min-width: #{$XS-size}) and (max-width: #{map-get($grid-breakpoints, sm) - 0.02})"
$breakpoint-sm-only: "(min-width: #{map-get($grid-breakpoints, sm)}) and (max-width: #{map-get($grid-breakpoints, md) - 0.02})"
$breakpoint-md-only: "(min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg) - 0.02})"
$breakpoint-lg-only: "(min-width: #{map-get($grid-breakpoints, lg)}) and (max-width: #{map-get($grid-breakpoints, xl) - 0.02})"
$breakpoint-xl-only: "(min-width: #{map-get($grid-breakpoints, xl)})"

$grid-column-width: 60px
$grid-gutter-width: 20px

// Mobile header settings
$mobile-header-height: 80px
