@use "sass:math"

.progress
  margin-bottom: 1.5rem
  background-image: linear-gradient(to bottom, darken($colour-wild-sand-grey, 0), lighten($colour-wild-sand, 1%))
  box-shadow: inset 0 1px 2px rgba($colour-black, 0.1)

  &-wrapper
    padding: 0

  .progress-bar
    &.bg-secondary
      color: $colour-text
      background-color: $colour-grey-light !important

  &-title
    font-family: $font-family-standard
    font-size: map-get($font-15, 'size')
    text-transform: uppercase
    letter-spacing: $base-unit*0.05
    margin-bottom: math.div($base-unit, 2)
    color: $colour-grey

    &-info
      font-family: $font-family-standard
      font-size: map-get($font-15, 'size')
      text-transform: none
      letter-spacing: 0
      margin-top: 0
      margin-bottom: math.div($base-unit, 2)
      color: $colour-grey
