.thumbnails
  @include clearfix()
  margin: 0 0 $base-unit*2 0
  list-style: none

  > li
    float: none
    margin-bottom: $base-unit*2.4
    height: 80px

    &:first-child
      margin-left: 0

.thumbnail
  @include border-radius(0)
  display: inline-block
  padding: 0
  border: none

  img
    margin: 0

a.thumbnail
  &:hover
    img
      box-shadow: 0 $base-unit*0.1 $base-unit*0.2 rgba($colour-true-black, 0.2)

  &:focus
    @include focus-box-shadow()

    @import 'thumbnails-md-up'
@import 'thumbnails-lg-up'
@import 'thumbnails-xl-up'
