.nav-local
  > ul
    border-bottom: $base-unit*0.1 solid $colour-tint-lighter

  ul
    list-style-type: none
    margin-left: 0

    .nav-parent
      background-color: $colour-grey-lighter
      color: $colour-link

    .nav-item
      border-bottom: $base-unit*0.1 solid $colour-tint-lighter

      .nav-link
        display: block
        text-decoration: none
        margin: 0
        padding: $base-unit*1.5
        font-family: $font-family-standard
        font-size: map-get($font-18, 'size')
        line-height: map-get($font-18, 'line-height')
        border-bottom: 0
        border-left: $base-unit*0.5 solid $colour-transparent

        &:hover
          text-decoration: underline

      &:last-child
        border-bottom: 0

      &.active
        > .nav-link
          color: $colour-red
          border-left-color: $colour-red
          border-bottom: 0

        .nav-children
          .nav-link
            padding-left: $base-unit*3.5

    .nav-children
      border-top: $base-unit*0.1 solid $colour-tint-lighter

@import 'nav-local-lg-up'
