@use "sass:math"

.alert
  @include border-radius(0)
  border: none
  font-family: $font-family-standard
  text-shadow: none
  color: $colour-grey-darker
  padding: $base-unit $base-unit*1.5
  background-color: $colour-pearl-lusta
  margin-bottom: $base-unit*2.4
  border-left: solid 4px darken($colour-alert-warning-bg, 10%)

  p, ul
    &:last-child
      margin-bottom: 0

  a
    text-decoration: underline

  &.alert
    &-error,
    &-info,
    &-success
      a
        color: $colour-white

        &:visited,
        &:active
          color: $colour-white
          text-decoration: none

        &:hover
          color: $colour-white
          text-decoration: underline

        &:focus
          color: $colour-grey-darker

  &.alert-error
    @include fontantialiasing()
    background-color: $colour-red
    color: $colour-white
    border-left: solid 4px darken($colour-red, 10%)

  &.alert-info
    color: $colour-white
    background-color: $colour-info
    border-left: solid 4px darken($colour-info, 10%)

  &.alert-success
    @include fontantialiasing()
    color: $colour-white
    background-color: $colour-green-haze
    border-left: solid 4px darken($colour-green, 10%)

  strong
    font-family: $font-family-standard
    font-weight: normal

  &.alert-block

    .alert-title
      color: $colour-tint-darkest
      margin-bottom: $base-unit

    &.alert-error,
    &.alert-info,
    &.alert-success
      .alert-title
        color: $colour-white
        margin-bottom: $base-unit

      a
        color: $colour-white

    &.alert-error
      @include intranet-border(darken($colour-red,16%))

    &.alert-info
      @include intranet-border(darken($colour-info,16%))

    &.alert-success
      @include intranet-border(darken($colour-green-haze,10%))

    ol
      margin-bottom: math.div($base-unit, 2)

    .alert-link
      margin-top: ($base-unit*2)

    .alert-link .btn
      color: $colour-tint-darkest
      background-color: $colour-scotch-mist
      border: ($base-unit*0.1) solid $colour-orinoco

    &.with-icon
      border-top: none
      border-left: ($base-unit*8) solid $colour-barley-white

      &.alert-info
        border-top: none
        border-left-color: (darken($colour-info,16%))

      &.alert-error
        border-top: none
        border-left: ($base-unit*8) solid (darken($colour-red,16%))

      &.alert-success
        border-top: none
        border-left: ($base-unit*8) solid (darken($colour-green-haze,10%))

      .icon
        @include square(($base-unit*4))
        margin-left: -5.25rem
        display: inline
        position: absolute

      .close
        top: -0.75rem
        right: -0.938rem

  .alert-title, h1, h2, h3, h4, h5, h6
    font-family: $font-family-standard
    font-size: map-get($font-24, 'size')
    line-height: map-get($font-24, 'line-height')
    margin-bottom: $base-unit
    display: block
    color: $colour-grey-darker

  &.with-icon
    display: flex
    padding: 0
    width: 100%

    &.alert,
    &.alert-warning
      .alert-icon
        background: darken($colour-pearl-lusta, 10%)

        .icon
          fill: $colour-black

    &.alert-error
      .alert-icon
        background: darken($colour-red, 10%)

        .icon
          fill: $colour-white

      .alert-title, h1, h2, h3, h4, h5, h6
        color: $colour-white

    &.alert-info
      .alert-icon
        background: darken($colour-info, 10%)

        .icon
          fill: $colour-white

      .alert-title, h1, h2, h3, h4, h5, h6
        color: $colour-white

    &.alert-success
      .alert-icon
        background: darken($colour-success, 10%)

        .icon
          fill: $colour-white

      .alert-title, h1, h2, h3, h4, h5, h6
        color: $colour-white

    .alert-icon
      min-width: $base-unit * 6.5
      padding: #{$base-unit * 2.5} #{$base-unit * 1.5}

      .icon
        +square($base-unit * 3.5)

    .alert-body
      padding: #{$base-unit * 2}

  &-site
    width: 100%
    padding: $base-unit*2 $base-unit
    background: $colour-bay-of-many
    font-family: $font-family-standard
    text-align: center
    color: $colour-white
    border-left: none
    margin-bottom: 0

    p
      margin-bottom: 0

    a
      color: $colour-white
      text-decoration: underline

      &:focus
        color: $colour-grey-darker

      &:hover,
      &:visited
        color: $colour-white

@import 'alert-sm-up'
