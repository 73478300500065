.content
  padding: ($base-unit*2) 0

  a:visited
    color: $colour-link-visited

.content-footer
  margin-top: ($base-unit*4)
  width: 100%

  .feedback
    text-align: right

@import "content-md-up"
@import "content-lg-up"
