.notice
  font-family: $font-family-standard
  color: $colour-grey-darker
  padding: $base-unit $base-unit*1.5
  margin-bottom: $base-unit*2.4
  border-left: solid 4px $colour-silver
  position: relative

  p, ul
    &:last-child
      margin-bottom: 0

  a
    text-decoration: underline

  strong
    font-family: $font-family-bold
    font-weight: normal

    a
      text-decoration: underline

      &:visited,
      &:active
        text-decoration: none

      &:hover
        text-decoration: underline

  .notice-title, h1, h2, h3, h4, h5, h6
    font-family: $font-family-standard
    color: $colour-grey-darker
    margin-bottom: $base-unit
    display: block

  &.notice--icon
    padding-left: $base-unit*4.2
    padding-bottom: 0
    border-left: none

    &::before
      content: ''
      position: absolute
      width: $base-unit*3
      height: $base-unit*3
      left: 0
      top: $base-unit*.7
      border-radius: 50%

    &::after
      content: ''
      position: absolute

    &--tick
      &::before
        background: $colour-green

      &::after
        top: $base-unit*1.3
        left: $base-unit
        transform: rotate(45deg)
        height: $base-unit*1.5
        width: $base-unit*.9
        border-bottom: 0.3rem solid $colour-white
        border-right: 0.3rem solid $colour-white

    &--cross
      &::before
        background: $colour-red

      &::after
        top: $base-unit*1.44
        left: $base-unit*.76
        width: $base-unit*1.6
        height: $base-unit*1.6
        background: linear-gradient(to bottom, transparent 35%, $colour-white 35%, $colour-white 65%,  transparent 65%), linear-gradient(to right, transparent 35%, $colour-white 35%, $colour-white 65%, transparent 65%)
        transform: rotate(45deg)
