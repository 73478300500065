
$franklin-gothic-book-it: 'franklin_gothic_fs_bookitalic'
$franklin-gothic-book: 'franklin_gothic_fs_bookRg'
$franklin-gothic-URW: 'franklin_gothic_URW_lig'
$franklin-gothic-med: 'franklin_gothic_fs_medregular'
$franklin-gothic-med-it: 'franklin_gothic_fs_meditalic'
$franklin-gothic-demi: 'franklin_gothic_fs_demi'
$tee-franklin-gothic-thin: "TeeFraThi"
$tee-franklin-gothic-light-obl: "TeeFraLigObl"

$system-font: "Helvetica Neue", "Helvetica", Arial, sans-serif
$system-font-alt: Georgia, 'Times New Roman', Times, serif

$font-weight-standard: 400
$font-weight-bold: 400

$font-family-italic: $franklin-gothic-book-it, $system-font
$font-family-standard: $franklin-gothic-book, $system-font
$font-family-bold: $franklin-gothic-med, $system-font
$font-family-alt: $system-font-alt
$font-family-base: $system-font
$font-family-URW: $franklin-gothic-URW, $system-font
$font-family-demi: $franklin-gothic-demi, $system-font
$font-family-light-obl: $tee-franklin-gothic-light-obl, $system-font

/* Progress steps */
$progress-steps-min: 2
$progress-steps-max: 13

// Font sizes
$font-11: ("size": 0.6875rem, "line-height": 1rem)
$font-12: ("size": 0.75rem, "line-height": 1rem)
$font-13: ("size": 0.8125rem, "line-height": 1rem)
$font-14: ("size": 0.875rem, "line-height": 1rem)
$font-15: ("size": 0.9375rem, "line-height": 1.3125rem)
$font-16: ("size": 1rem, "line-height": 1.375rem)
$font-17: ("size": 1.0625rem, "line-height": 1.5rem)
$font-18: ("size": 1.125rem, "line-height": 1.625rem)
$font-19: ("size": 1.1875rem, "line-height": 1.6875rem)
$font-20: ("size": 1.25rem, "line-height": 1.75rem)
$font-22: ("size": 1.375rem, "line-height": 1.75rem)
$font-24: ("size": 1.5rem, "line-height": 1.825rem)
$font-25: ("size": 1.5625rem, "line-height": 1.875rem)
$font-26: ("size": 1.625rem, "line-height": 2rem)
$font-27: ("size": 1.6875rem, "line-height": 2rem)
$font-28: ("size": 1.75rem, "line-height": 2.125rem)
$font-29: ("size": 1.8125rem, "line-height": 2.125rem)
$font-30: ("size": 1.875rem, "line-height": 2.25rem)
$font-34: ("size": 2.125rem, "line-height": 2.5rem)
$font-35: ("size": 2.1875rem, "line-height": 2.5rem)
$font-36: ("size": 2.25rem, "line-height": 2.5rem)
$font-38: ("size": 2.375rem, "line-height": 2.5rem)
$font-40: ("size": 2.5rem, "line-height": 2.5rem)
$font-44: ("size": 2.75rem, "line-height": 3.125rem)
$font-46: ("size": 2.875rem, "line-height": 3.375rem)
