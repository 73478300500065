@media #{$breakpoint-xl-up}
  .toolbox
    padding-bottom: $base-unit*4

    .toolbox
      &-links
        margin: 0 #{-$base-unit*1.75}
        padding: 0

      &-item
        width: 12.5%
        flex-basis: 12.5%
        padding: 0
        margin: 0

        a
          width: 100px

          .icon
            @include square(100px)

        &.empty
          .icon
            padding: $base-unit*4