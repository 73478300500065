.nav-global
  background: $colour-pickled-bluewood
  font-weight: normal
  text-shadow: none
  font-family: $font-family-URW
  margin: auto
  z-index: $zindex-modal-backdrop + 1
  width: 100%
  position: relative

  .navbar
    @extend .flex-row
    @extend .align-items-center
    @extend .justify-content-start
    max-width: map-get($container-max-widths, "lg")
    margin: auto
    padding: 0

    .navbar-inner
      padding: 0

  &-links-dropdown
    display: none
    position: absolute
    left: 0
    right: 0
    min-height: 340px
    overflow: hidden
    background-color: $colour-white
    padding: 0 $base-unit*1.5

    &.open
      display: block

      + .nav-link
        +global-nav-active-arrow

    .nav
      margin: 0

      > .nav-item
        margin-bottom: $base-unit*2.4

      .nav-item
        display: block
        width: 100%

        &:last-child
          margin-bottom: 0

        .nav-link
          font-family: $font-family-standard
          font-size: map-get($font-18, 'size')
          line-height: map-get($font-18, 'line-height')
          color: $colour-link
          padding: 0
          display: inline-block

          &:focus
            outline: none
            border: none
            box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn

          &:hover
            text-decoration: underline

        .nav-title
          font-family: $font-family-standard
          font-size: map-get($font-22, 'size')
          line-height: map-get($font-22, 'line-height')
          color: $colour-congress-blue

          + .nav
            margin-top: $base-unit*1.5

            .nav-item
              margin-bottom: $base-unit

              &:last-child
                margin-bottom: 0

  &-links
    display: flex
    justify-self: start
    margin: 0
    flex-wrap: nowrap

    .nav-link
      text-decoration: none

    > .nav-item

      > a:visited
        color: $colour-white

      > a:focus
        color: $colour-pickled-bluewood

        .icon
          fill: $colour-pickled-bluewood

      .icon
        +square($icon-15)

        fill: $colour-white
        margin-left: $base-unit*0.5

      > .nav-link
        display: inline-block
        max-width: 170px
        height: 100%
        font-size: map-get($font-19, 'size')
        line-height: map-get($font-19, 'line-height')
        padding: $base-unit*1.5 $base-unit
        position: relative

        &.active
          background-color: darken($colour-pickled-bluewood, 8%)

          &:focus
            background-color: $colour-sweet-corn

        &.with-dropdown
          color: $colour-white
          height: 100%
          cursor: pointer
          min-width: 95px

          &:focus
            color: $colour-pickled-bluewood

          &:hover:not(&:focus)
            background: $colour-comet

            .icon
              fill: $colour-white

      &.open
        > .nav-link
          &:hover:not(&:focus)
            color: $colour-white

        > .nav-link.with-dropdown
          @include global-nav-active-arrow()

          + .nav-global-links-dropdown
            display: block

      .nav-global-links-dropdown.show,
      .nav-global-links-dropdown.collapsing
        display: block
    .box
      .box-header .box-title,
      p
        font-family: $font-family-standard

@import "nav-global-1025-up"
@import "nav-global-xl-up"
@import "nav-global-1255-up"
