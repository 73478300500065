@media #{$breakpoint-lg-up}
  .circle-navigation
    margin: 0 auto
    float: none

    a:focus
      path
        fill: $colour-sweet-corn

      text
        fill: $colour-grey-darker
