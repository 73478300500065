body
  &.menu-open
    position: fixed
    width: 100%
    overflow: hidden

    .header-global
      position: sticky
      top: 0
      left: 0
      width: 100%

    .nav-global
      &-mobile
        transform: translateX(0)

  &.menu-closing
    .nav-global-mobile
      transform: translateX(-100%)

  &.search-open
    overflow: hidden

    .nav-global-mobile
      transition: all $animation-duration-standard $animation-mobile-menu-burger, $transition-collapse
