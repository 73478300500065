.map
  margin: 0 0 ($base-unit*2)
  display: table
  width: 100%

  .map-body
    width: 100%
    height: ($base-unit*20)
    background: $colour-grey-light

  .caption
    font-family: $font-family-light-obl
    background: $colour-grey-lighter
    padding: $base-unit
    display: table-caption
    caption-side: bottom

  .float-left,
  .float-right
    margin: 0 ($base-unit*2) ($base-unit*2) 0
    width: 50%

    .map-body
      height: ($base-unit*20)

  .float-right
    margin: 0 0 ($base-unit*2) ($base-unit*2)

  &.map-large .map-body
    height: ($base-unit*40)

  &.map-small .map-body
    height: ($base-unit*10)

  .leaflet-div-icon
    border: none
    background: none

  .leaflet-popup-content
    line-height: inherit

@import 'map-lg-up'
