.dictionary
  @include clearfix()
  margin-bottom: ($base-unit*4)

.nav-dictionary
  font-family: $font-family-bold

  li
    margin-bottom: ($base-unit*0.5)

    a
      @include border-radius(0)
      border: none
      color: $colour-grey
      background-color: $white
      padding: 0 ($base-unit * 1.2)
      line-height: ($base-unit*3)
      height: ($base-unit*3)
      margin: 0
      box-sizing: border-box

    &.active a
      color: $colour-white
      background-color: $colour-black

    > a:hover
      color: $colour-grey-darker
      background-color: $colour-grey-light

  .disabled
    padding: 0 12px 0 12px
    line-height: ($base-unit*3)
    height: ($base-unit*3)
    color: $colour-grey-light
    box-sizing: border-box
    margin-right: 4px

.dictionary-letter
  @include clearfix()
  border-top: 2px solid $colour-grey-light
  padding: $base-unit*2 0 $base-unit*2 0
  position: relative
  min-height: ($base-unit*5)

.dictionary-letter-title
  font-family: $font-family-bold
  font-size: map-get($font-44, 'size')
  line-height: map-get($font-44, 'line-height')
  color: colour-red
  width: $base-unit*10
  position: absolute
  left: 0
  top: $base-unit*2

.dictionary-terms
  margin: 0 0 0 ($base-unit*14)
  padding: 0
  list-style: none
  column-gap: ($base-unit*2)

.dictionary-term
  padding-bottom: $base-unit
  break-inside: avoid

  small
    font-size: map-get($font-17, 'size')
    display: block
    margin-left: $base-unit*2

@import "dictionary-sm-down"
@import "dictionary-sm-up"
@import "dictionary-lg-only"
@import "dictionary-xl-up"
