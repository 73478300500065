/* Landscape tablet to desktop */
@media #{$breakpoint-lg-up}
  .pagination
    .page-item
      .page-link
        &:focus
          outline: #{$base-unit * 0.3} solid $colour-sweet-corn

    &:first-child,
    &:last-child
      .page-link
        &:focus
          outline: none
          box-shadow: 0 0 0 ($base-unit * 0.3) $colour-sweet-corn
