@media #{$breakpoint-sm-up}
  .profile-large
    &.with-image
      padding-left: ($profiles-large-image-width + ($base-unit*4))

    .profile-image
      float: left
      margin-left: (-($profiles-large-image-width + ($base-unit*4)))
      width: $profiles-large-image-width

      img
        @include square($profiles-large-image-width)

    .profile-title
      font-size: map-get($font-34, 'size')
      margin-right: 0
