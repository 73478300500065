.image
  margin: 0 0 ($base-unit*2)
  display: block

  .caption
    background: $colour-tint-lightest
    padding: $base-unit
    font-size: map-get($font-17, "size")
    font-style: normal
    display: block
    caption-side: bottom
    width: 100%
    color: $colour-tint-darker

    span
      padding: 0 $base-unit
      display: block

  img
    display: block
    max-width: 100%

    & + br
      display: none

  &.float-left
    margin: 0 ($base-unit*2) ($base-unit*2) 0

  &.float-right
    margin: 0 0 ($base-unit*2) ($base-unit*2)

img
  max-width: 100%
  height: auto
  vertical-align: middle
  border: 0
