.event
  &-header
    border-bottom: $base-unit*0.3 solid $colour-grey-lighter
    margin-bottom: $base-unit*3

    &-date
      font-size: map-get($font-20, 'size')
      margin-bottom: 0

      .icon
        @include square($icon-20)
        margin-bottom: $base-unit*0.6

  &-date
    display: inline-block
    width: 40px
    color: $colour-haiti
    text-align: center
    vertical-align: top

    & > time
      display: block
      line-height: 1

    &-day
      font-family: $font-family-bold
      font-size: map-get($font-38, 'size')

    &-month
      font-size: map-get($font-24, 'size')
      margin-bottom: $base-unit*0.5

    &-year
      font-size: map-get($font-14, 'size')
