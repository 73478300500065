.circle-navigation
  svg
    margin-bottom: $base-unit*2

  path,
  text
    cursor: pointer
    font-weight: bold

  path
    stroke: $colour-white
    stroke-width: 3px

@import 'circle-navigation-lg-up'
