@use "sass:math"

.list
  &.list-links
    margin: 0 0 ($base-unit) 0
    padding: 0
    list-style: none

    li
      margin: 0 0 math.div($base-unit, 2) 0

      a
        font-style: normal
        text-decoration: none

        &:hover
          text-decoration: underline

    .list-links
      margin-left: ($base-unit*2)
      margin-top: math.div($base-unit, 2)

  &.list-horizontal
    @include clearfix()
    list-style: none
    margin: 0 0 ($base-unit*2) 0
    padding: 0

    li,
    dd,
    dt
      display: block
      float: left
      margin-right: ($base-unit*2)

      a
        text-decoration: none

        &:hover
          text-decoration: underline
    dt
      clear: left

    &--with-heading
      font-size: map-get($font-19, 'size')
      line-height: map-get($font-19, 'line-height')
      display: flex
      padding: $base-unit 0
      flex-direction: column
      border-top: $base-unit*0.1 solid $colour-grey-light
      border-bottom: $base-unit*0.1 solid $colour-grey-light

      .list__title
        font-family: $font-family-bold
        flex-grow: 0
        flex-shrink: 0
        margin-bottom: $base-unit

      .list__content
        display: flex
        flex-wrap: wrap
        flex-grow: 1
        margin: 0
        flex-direction: column

        li
          font-size: map-get($font-19, 'size')
          line-height: map-get($font-19, 'line-height')

  &.list-icons
    margin: 0

    li
      margin-right: $base-unit
      list-style: none

      .icon
        fill: $colour-red
        margin: 0 $base-unit $base-unit*0.3 0
        padding-right: 0

      a
        display: inline-block
        text-decoration: none

      a[href^="mailto:"]
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 100%

        &:hover
          text-decoration: underline

    &.icons-
      &medium
        li
          .icon
            @include square($icon-24)
            margin-right: ($base-unit*2)

      &large
        li
          .icon
            @include square($icon-32)
            margin-right: ($base-unit*2.5)

      &mini
        li
          .icon
            @include square($icon-20)

    .icon-tile
      @include square($icon-24)
      margin-right: ($base-unit)

  &-contact
    list-style: none
    margin: 0 0 ($base-unit*2) 0
    padding: 0
    font-family: $font-family-standard
    font-size: map-get($font-17, 'size')
    line-height: map-get($font-17, 'line-height')

    li
      margin-bottom: math.div($base-unit, 2)
      clear: left
      display: flex
      align-items: top

      a
        text-decoration: none

        &:hover
          text-decoration: underline

      a[href^="mailto:"]
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    .icon
      float: left
      display: block
      flex-shrink: 0

  &-event
    &-details
      font-size: map-get($font-16, "size")
      margin: 0 0 ($base-unit) 0

      li
        margin-right: $base-unit
        list-style: none
        padding-left: $base-unit*2.6
        text-indent: -$base-unit*2.6
        overflow-wrap: break-word

        .icon
          width: $base-unit*1.6
          height: $base-unit*1.6
          padding: 0
          display: inline-block

    &-details,
    &-calendar
      padding: 0
      list-style: none

      li
        margin: 0 0 math.div($base-unit, 2) 0

        a
          font-style: normal

    &-calendar
      margin: 0 0 ($base-unit*3) 0

  &-tags
    @include clearfix()
    list-style: none
    margin: 0 0 $base-unit*1.5 0
    padding: 0

    &.teaser-tags
      margin-top: $base-unit
      margin-bottom: 0

  &-steps-small
    list-style: none
    counter-reset: li
    margin-left: 0

    &-item
      counter-increment: li
      position: relative
      padding: 0 0 0 ($base-unit * 3)
      list-style: none
      min-height: ($base-unit * 4)

      &:before
        content: counter(li) " "
        position: absolute
        top: 0
        left: 0
        width: ($base-unit * 2)
        height: ($base-unit * 2)
        border-radius: ($base-unit * 2)
        line-height: ($base-unit * 2)
        margin-right: ($base-unit)
        color: $colour-white
        background: $colour-red
        font-family: $font-family-bold
        text-align: center
        font-size: map-get($font-12, 'size')

  &-profiles
    @include clearfix()
    margin: 0
    padding: 0
    list-style: none

    .profile
      margin-bottom: $base-unit

      a
        text-decoration: none

  &-teasers
    @include clearfix()
    margin: 0 0 0 (-($base-unit*2))
    padding: 0
    list-style: none

    .teaser
      margin-bottom: $base-unit

.form-new
  .list
    &.list-icons
      li
        position: relative
        padding-left: ($base-unit * 3)
        margin-bottom: ($base-unit)

        .icon
          position: absolute
          top: 0
          left: 0

          &.colour-success
            color: $colour-success
            fill: $colour-success

@import list-md-up
@import list-lg-up
