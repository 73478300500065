@use "sass:math"

$profiles-large-image-width: $base-unit*20
$profiles-image-width: $base-unit*10
$profiles-small-image-width: $base-unit*4.0

.profile
  @include clearfix()
  margin-bottom: ($base-unit*2)
  position: relative

  &.with-image
    padding-left: $profiles-image-width + $base-unit*2

  abbr[title],
  acronym[title]
    border: none

  .profile-title
    font-family: $font-family-standard
    font-size: map-get($font-20, 'size')
    line-height: map-get($font-17, 'line-height')
    margin: 0
    color: $colour-red
    letter-spacing: -$base-unit*0.05

    a
      color: $colour-red

      &:focus
        color: darken($colour-red, 10%)

.profile-vertical
  text-align: center

  &.with-image
    padding-left: 0

  .profile-image
    margin-left: 0
    margin-bottom: $base-unit
    float: none
    width: 100%

  .profile-title
    .profile-title-suffix
      display: block
      font-size: map-get($font-20, 'size')

  .profile-connect
    position: static

    li
      text-align: center
      display: inline-block
      float: none

    a.icon
      color: $colour-grey-light

      &:hover
        color: $colour-grey

.profile-image
  @include square($profiles-image-width)
  margin: 0
  margin-left: (-($profiles-image-width + ($base-unit*2)))
  float: left

  img
    box-shadow: 0 0 2px rgba($colour-black, 0.2)

  .profile-title-suffix
    color: $colour-black

    &:after
      content: ''
      margin-left: 0
      display: none

.profile-bio
  font-size: map-get($font-15, 'size')
  margin-bottom: $base-unit

  p:last-of-type
    margin-bottom: 0

.profile-subtitle
  font-family: $font-family-standard
  font-size: map-get($font-17, 'size')
  line-height: map-get($font-17, 'line-height')
  margin: 0
  color: $colour-black
  letter-spacing: math.div(-$base-unit, 40)

  a:after
    content: ''
    margin-left: 0

.profile-department
  font-family: $font-family-URW
  font-size: map-get($font-15, 'size')
  line-height: map-get($font-15, 'line-height')
  color: $colour-grey-darker
  margin: 0 0 $base-unit 0

.profile-connect
  position: absolute
  right: 0
  top: 0

.profile-contact
  @include clearfix()
  margin: 0 0 $base-unit 0
  float: left

  dt
    display: inline-block
    clear: left
    float: left

    svg
      color: $colour-grey
      margin-right: math.div($base-unit, 2)
      margin-top: -$base-unit*0.4

  dd
    display: inline-block
    clear: none
    float: left
    margin-left: 0

  dd + dd
    clear: left
    margin-left: $base-unit*3
    margin-top: -$base-unit*0.2

.profile-contact-telephone,
.profile-contact-fax
  cursor: pointer

.profile-small
  &.with-image
    padding-left: ($profiles-small-image-width + ($base-unit))

  .profile-image
    @include square($profiles-small-image-width)
    margin-left: (-($profiles-small-image-width + ($base-unit)))

    img
      @include square($profiles-small-image-width)

  .profile-title
    margin-top: $base-unit*0.2
    font-size: map-get($font-20, 'size')
    line-height: 1.125rem

  .profile-subtitle
    font-size: map-get($font-17, 'size')
    line-height: map-get($font-17, 'line-height')

.profile-large
  &.with-image
    padding-left: 0

  .profile-image
    float: right
    margin-left: (-(($profiles-small-image-width) + $base-unit*3))
    width: ($profiles-small-image-width + $base-unit*3)
    height: auto

    img
      @include square($profiles-small-image-width + $base-unit*3)
      box-shadow: none
      margin-bottom: ($base-unit*4)

  .profile-title
    margin-right: (($profiles-small-image-width) + $base-unit*5)
    font-size: map-get($font-24, 'size')
    line-height: 2.5rem

  .profile-connect
    left: 0
    top: $base-unit*22

  .profile-contact
    -webkit-column-count: 1
    -webkit-column-gap: 0

.profile-current-position
  color: $colour-tint-prime
  font-size: map-get($font-17, 'size')

@import 'profile-sm-up'
