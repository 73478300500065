.content-group
  @include clearfix()
  padding: $base-unit*4 0

  &:not(.with-background) + &:not(.with-background)
    padding-top: 0

  &:first-child
    padding-top: 0

  &.content-group-applications
    padding: $base-unit 0 $base-unit*1.5

  &-title
    display: flex
    align-items: center
    color: $colour-grey-darker

    .icon
      height: 29px
      width: 24px
      margin-right: $base-unit*2

@import 'content-group-md-up'
@import 'content-group-lg-up'
