@use "sass:math"

$input-height: $base-unit*4.5

input[type="radio"],
input[type="checkbox"]
  margin-right: $base-unit*.7

form, #questions
  margin-bottom: $base-unit*2.4

  legend
    border-bottom: $base-unit * 0.1 solid $colour-mercury
    font-family: $font-family-URW
    color: $colour-grey-darker
    margin-bottom: $base-unit * 2.4
    line-height: 3rem

    &.sq-form-question-title
      font-family: $font-family-bold
      font-size: map-get($font-17, 'size')
      line-height: map-get($font-17, 'line-height')
      border-bottom: none

  &.form
    &-inline
      width: 100%
      position: relative

      input[type="text"]
        &#query
          width: 100%

      .form-group
        display: contents

      .form-control
        width: 100%

        &.search-query
          padding-right: $base-unit*4

      select,
      textarea,
      input[type="text"],
      input[type="password"],
      input[type="datetime"],
      input[type="datetime-local"],
      input[type="date"],
      input[type="month"],
      input[type="time"],
      input[type="week"],
      input[type="number"],
      input[type="email"],
      input[type="url"],
      input[type="search"],
      input[type="tel"],
      input[type="color"],
      .form-group,
      label
        margin-right: math.div($base-unit, 2)
        margin-bottom: $base-unit

      label
        justify-content: left

      .search-submit
        background: $colour-transparent
        width: 40px
        height: 41px
        position: absolute
        top: 2px
        right: 7px
        border: none
        padding: 0

        .icon
          @include square($icon-24)
          fill: $colour-grey-dark
          margin-right: 0

      .btn
        margin-bottom: $base-unit
        margin-right: 0
        border: none

  &.form-horizontal
    .form-group,
    .control-group
      &:before,
      &:after
        @include clearfix()

      .control-label
        float: none
        width: auto
        padding-top: math.div($base-unit, 2)

  .controls
    margin-left: 0

  .form
    &-group
      margin-top: $base-unit*3

      &:not([class*="col"]):first-of-type
        margin-top: 0

      &.sub-question
        margin-top: 0
        border-left: ($base-unit*0.5) solid $colour-grey-lighter
        padding-top: $base-unit
        padding-left: $base-unit*2

      .col-form-label
        margin-right: 0
        padding: $base-unit*0.5 0 0 $base-unit*1.6
        margin-bottom: $base-unit * 0.8

    &-hint
      color: $colour-grey-darkest
      font-family: $font-family-standard

    &-controls
      margin-top: ($base-unit * 4)

    &-search
      input[type='text'],
      input[type='password'],
      input[type='datetime'],
      input[type='datetime-local'],
      input[type='date'],
      input[type='month'],
      input[type='time'],
      input[type='week'],
      input[type='number'],
      input[type='email'],
      input[type='url'],
      input[type='search'],
      input[type='tel'],
      input[type='color']
        padding: $base-unit*1.5 $base-unit*3.5 $base-unit*1.5 $base-unit*1.5

      &.form-search-large
        select,
        textarea,
        input[type='text'],
        input[type='password'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='date'],
        input[type='month'],
        input[type='time'],
        input[type='week'],
        input[type='number'],
        input[type='email'],
        input[type='url'],
        input[type='search'],
        input[type='tel'],
        input[type='color']
          @include border-radius($base-unit*0.2)
          font-size: map-get($font-20, 'size')
          padding: $base-unit*2 $base-unit*3.8 $base-unit*2 $base-unit*2

      &.form-search-larger
        select,
        textarea,
        input[type='text'],
        input[type='password'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='date'],
        input[type='month'],
        input[type='time'],
        input[type='week'],
        input[type='number'],
        input[type='email'],
        input[type='url'],
        input[type='search'],
        input[type='tel'],
        input[type='color']
          @include border-radius($base-unit*0.2)
          font-size: map-get($font-20, 'size')
          padding: $base-unit*3 $base-unit*4 $base-unit*3 $base-unit*3

  .radio-option,
  .checkbox-option
    position: relative
    padding-left: 0
    margin-bottom: 0

    label
      cursor: pointer

  .validation-errors
    border: $base-unit*0.1 solid $colour-red
    padding: $base-unit*2

    h4
      margin-bottom: $base-unit

    ul
      margin-bottom: 0

      li
        color: $colour-red

        a,
        a:hover
          color: $colour-red
          text-decoration: none

  .question-error
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"]
      border-color: $colour-red

    &-msg
      color: $colour-red

  .highlighted-info
    width: calc(100% - #{$base-unit * 2})
    padding: ($base-unit * 2)
    border-left: $base-unit*0.5 solid $colour-red
    margin-left: ($base-unit * 2)

    p
      &:first-child
        margin-bottom: 0
        font-family: $font-family-bold

      &:last-of-type
        margin-bottom: 0

  .accordion-small
    .accordion-toggle
      font-size: map-get($font-17, 'size')

  .controls,
  .sq-form-question,
  .decision-question
    ul li
      border: none

      &:hover
        border: none

      label
        background: $colour-transparent

  select,
  select.form-control,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"]
    @include border-radius($base-unit*0.4)
    padding: $base-unit
    height: $input-height
    border: $base-unit*0.2 solid $colour-grey-darker
    width: 100%

  input[type="search"]
    padding-right: $base-unit*3

  select
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat calc(100% - 10px) 50%
    appearance: none

    &[multiple="multiple"]
      background: none
      height: auto

    &:not([multiple="multiple"])
      padding: $base-unit $base-unit*3 $base-unit $base-unit*1.5

  textarea
    @include border-radius($base-unit*0.4)
    height: auto
    padding: $base-unit
    border: $base-unit*0.2 solid $colour-grey-darker
    width: 100%
    min-height: ($base-unit * 12)

  h2 label
    font-family: $font-family-standard

  label,
  .control-label
    font-family: $font-family-bold

    &.radio,
    &.checkbox
      padding: $base-unit * 0.7 0 0 $base-unit * 4.5
      min-height: $base-unit * 3.4
      position: relative
      margin-bottom: $base-unit
      display: block
      font-family: $font-family-standard

    input[type="radio"],
    input[type="checkbox"]
      opacity: 1

  input[type="radio"],
  input[type="checkbox"]
    height: ($base-unit * 3.4)
    width: ($base-unit * 3.4)
    position: absolute
    left: 0
    top: 0
    opacity: 0
    margin-left: 0

    & + label
      cursor: pointer

    &:focus
      & + label
        outline: none

        &:before
          box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn

  .plaintextedit[type=text],
  .tag-lookup-input,
  #address-lookup-input
    height: auto !important

  li, td
    position: relative

  input[type="checkbox"]:checked + label::after,
  input[type="radio"]:checked + label::after
    opacity: 1

  input[type="checkbox"] + label,
  input[type="radio"] + label
    padding: $base-unit*0.7 0 0 $base-unit*4.5
    min-height: $base-unit * 3.4
    position: relative
    margin-bottom: $base-unit
    font-family: $font-family-standard

    &::before
      @include border-radius($base-unit*0.4)
      content: ""
      border: $base-unit*0.2 solid $colour-grey-darker
      height: ($base-unit * 3.4)
      width: ($base-unit * 3.4)
      position: absolute
      left: 0
      top: 0

    &::after
      content: ""
      position: absolute
      top: $base-unit * 0.9
      left: $base-unit * 0.7
      width: ($base-unit * 2)
      height: $base-unit
      opacity: 0

  input[type="checkbox"] + label
    &::after
      border: solid
      border-width: 0 0 $base-unit*0.5 $base-unit*0.5
      border-top-color: transparent
      transform: rotate(-45deg)

  input[type="radio"] + label
    &::before
      @include border-radius(50%)

    &::after
      @include border-radius(50%)
      top: $base-unit * 0.7
      height: ($base-unit * 2)
      background: $colour-grey-darker

  .success-message,
  .warning-message,
  .error-message
    margin-bottom: $base-unit*0.8

  .success-message
    color: $colour-green

  .error-message
    color: $colour-red

  .help-inline,
  .help-block
    color: $colour-grey

  .help-inline
    padding-left: $base-unit*0.5

  .help-block
    margin-bottom: $base-unit*1.2
    margin-top: $base-unit*1.2
    display: block

  .success
    .help-block,
    .help-inline,
    label,
    input,
    select,
    textarea
      color: $colour-valid

    .help-block
      .icon
        fill: $colour-valid

    input,
    select,
    textarea
      border-color: $colour-valid

  .error
    .help-block,
    .help-inline,
    label,
    input,
    select,
    textarea
      color: $colour-red

    .help-block
      .icon
        fill: $colour-red

    input,
    select,
    textarea
      border-color: $colour-red
      background-image: none

  input[type="checkbox"],
  input[type="radio"],
  select
    &:hover
      cursor: pointer

  .well
    margin-top: $base-unit*2.4

.form-label,
label
  & + .question-error-msg
    margin-top: -$base-unit*0.8

.form-actions
  padding: $base-unit*2
  margin-top: $base-unit*2.4
  margin-bottom: $base-unit*2.4
  background-color: $colour-wild-sand-grey
  border-top: $base-unit*0.1 solid $colour-mercury

input:invalid
  color: $colour-red

abbr.sq-form-required-field[title]
  color: $colour-red
  border-bottom: none

.sq-form
  &-required-field-note
    margin-bottom: $base-unit*2

  &-question
    margin-bottom: $base-unit*2
    @include clearfix()

    &-title
      margin-left: 0
      width: auto
      padding-top: $base-unit * 0.5

    &-note
      @extend .help-block

    &-error
      input
        border-color: $colour-red

      .sq-form-question-title
        color: $colour-red

    &-answer
      ul
        list-style-type: none
        margin: 0 0 $base-unit 0

        li
          input
            float: left
            margin-right: $base-unit

    &-option-list
      .sq-form-option-answer
        li
          display: block

        label
          display: inline-block
          margin-bottom: 0

  &-error
    color: $colour-red
    margin: $base-unit 0 0 0

@import form-md-up
