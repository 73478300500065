.nav-global-mobile
  transition: all $animation-duration-standard $animation-mobile-menu-burger, $transition-collapse
  transform: translateX(-100%)
  z-index: $zindex-modal-backdrop + 1
  position: fixed
  background-color: $colour-white
  width: 100%
  bottom: 0
  left: 0
  height: calc(100% - #{$mobile-header-height})
  overflow-y: scroll

  p
    margin-bottom: $base-unit*2.4

  ul
    margin-left: 0
    list-style-type: none

  &-title
    font-size: map-get($font-30, 'size')
    line-height: map-get($font-30, 'line-height')
    font-family: $font-family-URW
    color: $colour-red
    margin-bottom: $base-unit*2

    a
      text-decoration: underline

  &-header
    margin: $base-unit*2

    &-back
      display: flex
      align-items: center
      color: $body-color

      .icon
        @include square($icon-12)
        margin-right: $base-unit*1.5

  &-account
    margin: $base-unit*3 $base-unit*2 $base-unit*4
    font-size: map-get($font-22, 'size')
    line-height: map-get($font-22, 'line-height')

    .icon
      margin-right: $base-unit

    &-logout,
    &-context
      display: flex
      align-items: center
      color: $body-color

      .icon
        width: 20px
        height: 29px

    &-context
      .icon
        @include square($icon-24)

    &-divider
      margin: $base-unit*4 -#{$base-unit*2} $base-unit*3

  &-inner
    .nav
      &-item
        font-size: map-get($font-22, 'size')
        line-height: map-get($font-22, 'line-height')
        border-bottom: $base-unit*0.1 solid $colour-grey-light

        &:first-child
          border-top: $base-unit*0.1 solid $colour-grey-light

          .nav-link
            min-height: $base-unit*6.8

        &-expandable
          display: flex

      &-link
        display: flex
        flex-basis: 100%
        align-items: center
        color: $colour-black
        padding: $base-unit*1.5 $base-unit*2
        min-height: $base-unit*6.9

        &.active
          color: $colour-red
          font-family: $font-family-bold

        &-expand
          border-left: $base-unit*0.1 solid $colour-silver

          .icon
            @include square($icon-14)
            margin: $base-unit*2.8
            pointer-events: none

            path
              fill: $colour-tapa

  &-next
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 100%
    transform: translate(100%, 0%)

    &.transitioning
      transition: transform $animation-duration-standard $animation-mobile-menu-burger
      transform: translate(0%, 0%)

  &-previous
    &.transitioning
      transform: translate(-100%, 0)
      transition: transform $animation-duration-standard $animation-mobile-menu-burger

  &-next.back
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 100%
    transform: translate(-100%, 0%)

    &.transitioning
      transition: transform $animation-duration-standard $animation-mobile-menu-burger
      transform: translate(0%, 0%)

  &-previous.back
    &.transitioning
      transform: translate(100%, 0)
      transition: transform $animation-duration-standard $animation-mobile-menu-burger

  > .loading
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
