@media #{$breakpoint-lg-up}
  .map
    #map
      &:focus
        box-shadow: 0 0 0 ($base-unit * 0.3) $colour-sweet-corn

      a, img
        &:focus
          box-shadow: 0 0 0 ($base-unit * 0.3) $colour-sweet-corn
          outline: none

    .leaflet-control-attribution
      a
        &:focus
          background-color: $colour-sweet-corn

    .leaflet-control-zoom
      a
        &:focus
          background-color: transparent
