.modal
  &-header
    h3
      font-size: map-get($font-17, 'size')

    &.modal-image
      padding: $base-unit*1.2 $base-unit*1.5 $base-unit*2.2 $base-unit*1.5
      border-bottom: 0

    .close
      margin: $base-unit*0.2 0 0 0
      padding: 0
      opacity: 0.5
      .icon
        @include square($icon-12)
        margin: 0

      &:hover,
      &:focus
        text-decoration: none
        cursor: pointer
        opacity: 0.4

  &-content
    background-clip: border-box

  &-body
    overflow-y: auto
    max-height: none
    padding: ($base-unit*1.5)

    .image
      margin: 0

    .image.float-left
      margin: 0 ($base-unit*2) ($base-unit*2) 0

    .image.float-right
      margin: 0 0 ($base-unit*2) ($base-unit*2)

  &-form
    margin-bottom: 0

  &-footer
    @include border-radius(0)
    @include clearfix()
    padding: $base-unit*1.4 $base-unit*1.5 $base-unit*1.5
    margin-bottom: 0
    border-top: 0
    text-align: right
    background-color: $colour-grey-lightest
    box-shadow: inset 0 $base-unit*0.1 0 $colour-white

    .btn + .btn
      margin-left: ($base-unit*0.5)
      margin-bottom: 0

    .btn-group .btn + .btn
      margin-left: -$base-unit*0.1

    .btn-block + .btn-block
      margin-left: 0

  &.modal-image
    .close
      @include square($base-unit*4)
      line-height: ($base-unit*4)
      opacity: 1
      position: absolute
      top: ($base-unit*-0.5)
      right: $base-unit
      background-color: $colour-black
      text-align: center
      padding: 0
      z-index: 1

      &:hover
        opacity: 1

      .icon-close
        margin-top: ($base-unit*0.5)
        margin-left: ($base-unit*0.5)

        .icon
          fill: $colour-white

    .modal-body
      padding: 0

    .image
      img
        width: 100%

@import "modal-md-up"
@import "modal-lg-up"
