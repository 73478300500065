@media #{$breakpoint-lg-up}
  .search
    &-results
      .teaser
        a
          &:focus
            background-color: $colour-sweet-corn
            text-decoration: none

            img
              outline: $base-unit*0.3 solid $colour-sweet-corn

    &-facet
      .main-facet
        a
          &:focus
            background-color: $colour-transparent
