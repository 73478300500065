@media #{$breakpoint-lg-up}
  .modal
    .modal-header,
    &.modal-image
      button
        &:focus,
        &:not(:disabled):not(.disabled):focus
          background-color: $colour-sweet-corn
          outline: none
          opacity: 1
          outline: $base-unit*0.3 solid $colour-sweet-corn

    &.modal-image
      button
        &:focus,
        &:not(:disabled):not(.disabled):focus
          background: $colour-black
