@media #{$breakpoint-lg-up}
  .media-list
    .media
      a
        &:focus
          outline: $base-unit*0.2 solid $colour-sweet-corn
          background-color: $colour-transparent
          box-shadow: none

          img
            box-shadow: none

      .media-body
        a
          &:focus
            background-color: $colour-sweet-corn
            color: $colour-black
            outline: none
            box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn
            text-decoration: none

        .media-reply
          a
            &:focus
              color: $colour-link
