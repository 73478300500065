$toolbar-zindex: 5000
$toolbar-zindex-select-caret: 4900

.apps-toolbar
  z-index: $toolbar-zindex
  background: rgba($colour-pickled-bluewood, 0.95)
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  text-align: center
  overflow-y: scroll
  padding: 0px $base-unit*0.5 $base-unit*4
  color: $colour-white

  p
    font-family: $font-family-URW
    margin: 0

  h1
    color: $colour-white
    margin-top: $base-unit*7
    font-size: map-get($font-46, 'size')
    line-height: map-get($font-46, 'line-height')

  .apps-toolbar
    &-icon
      fill: $colour-anakiwa-2
      margin-right: $base-unit*2

      .icon
        @include square(35px)
        fill: $colour-anakiwa-2
        margin: 0

      &-close
        background: none
        border: 0
        padding: $base-unit
        position: absolute
        right: 10px
        top: 10px
        z-index: $toolbar-zindex+1

        .icon
          @include square(28px)
          fill: $colour-white
          margin: 0

    &-selected
      margin: $base-unit*5 0 $base-unit*6

      &-item
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        background: $colour-mirage
        border-radius: $base-unit*0.3
        padding: $base-unit*0.8 $base-unit*1.5
        margin: 0 0 $base-unit*1.5

        &-text
          color: $colour-anakiwa-2
          text-align: left
          flex-basis: calc(100% - 130px)

        &-remove
          @include square(23px)
          cursor: pointer
          background: $colour-red
          border-radius: 100%
          order: 2
          text-decoration: none
          transition: background $animation-duration-app-icons-remove $animation-app-icons-remove

          &:before
            content: '-'
            font-size: map-get($font-40, 'size')
            line-height: 1rem
            color: $colour-white
            text-align: center

          &:hover
            background: $colour-btn-primary-bg-highlight
            text-decoration: none

        &-dragdrop
          margin-right: $base-unit*2
          cursor: pointer
          z-index: $toolbar-zindex+1

          .icon
            @include square(20px)
            fill: $colour-white
            margin: 0

        &-empty
          display: none

    &-slider
      color: white
      font-size: map-get($font-15, 'size')
      line-height: map-get($font-15, 'line-height')
      display: flex
      flex-wrap: wrap
      justify-content: flex-start

      &-options
        margin-bottom: $base-unit*6

        .apps-toolbar-select-wrapper
          width: 100%
          margin-bottom: $base-unit*3
          position: relative

          &::after
            content: ''
            border-style: solid
            border-width: $base-unit*0.8 $base-unit*0.8 0 $base-unit*0.8
            border-color: $colour-white $colour-transparent $colour-transparent $colour-transparent
            position: absolute
            top: 22px
            right: 27px
            z-index: $toolbar-zindex-select-caret

        select,
        input
          background-color: $colour-transparent
          color: $colour-white
          background-image: none
          appearance: none
          box-shadow: none
          position: relative
          padding: $base-unit*0.5 $base-unit*8.7 $base-unit*0.5 $base-unit*1.5
          width: 100%
          opacity: 1
          height: 50px
          border: $base-unit*0.1 solid $colour-white
          border-radius: 0

        select
          cursor: pointer
          z-index: $toolbar-zindex-select-caret+1

          &::-ms-expand
            display: none

          option
            background-color: $colour-white
            color: $colour-grey

        input
          &::placeholder
            color: $colour-white
            opacity: 1

          &:-internal-autofill-selected,
          &:-webkit-autofill
            -webkit-text-fill-color: $colour-white
            -webkit-box-shadow: 0 0 0px 1000px #{$colour-transparent} inset
            transition: background-color 5000s ease-in-out 0s

          &::-ms-clear
            display: none

        form
          position: relative

          .clear,
          .search
            display: flex
            background: $colour-transparent
            position: absolute
            top: 1px
            transition: background $animation-duration-app-icons-remove $animation-app-icons-remove

            &:hover
              background: $colour-white

              .icon
                fill: rgba($colour-pickled-bluewood, 0.95)

            .icon
              fill: $colour-white
              margin: 0

          .clear
            border: 0
            padding: $base-unit*1.7 $base-unit*1.3
            right: 50px

            .icon
              @include square(14px)

          .search
            padding: $base-unit*1.6
            border: 0
            border-left: $base-unit*0.1 solid $colour-white
            right: 1px

            .icon
              @include square(16px)

      &-item
        width: 100%
        display: flex
        align-items: center
        margin: 0 0 $base-unit*3
        padding: 0 0 $base-unit
        border-bottom: $base-unit*0.1 solid $colour-anakiwa-2

        p
          font-family: $font-family-standard

        .icon
          @include square(40px)
          margin-right: $base-unit*2.5
          fill: $colour-white

        &-icon
          @include square(25px)
          background-color: $colour-apple
          cursor: pointer
          border-radius: 100%
          margin-left: auto
          transition: background $animation-duration-app-icons $animation-app-icons

          &:hover
            text-decoration: none

          &::before
            content: '+'
            font-size: map-get($font-20, 'size')
            color: $colour-white

          &.disabled
            opacity: 0.1
            cursor: initial

          &:not(.disabled)
            &:hover
              background: $colour-white
              border-color: $colour-white

              &::before
                color: $colour-black

        &.active
          color: $colour-anakiwa-2

          .apps-toolbar
            &-icon
              .icon
                fill: $colour-anakiwa-2

            &-slider-item-icon
              background-color: $colour-red
              transition: background $animation-duration-app-icons-remove $animation-app-icons-remove

              &:hover
                background-color: $colour-btn-primary-bg-highlight
                border-color: $colour-btn-primary-bg-highlight

                &::before
                  color: $colour-white

              &::before
                content: '-'

  .ui
    &-sortable
      margin: 0 0 $base-unit*3.5
      list-style-type: none

    &-state-highlight
      width: 100%
      height: 51px
      background: rgba($colour-anakiwa-2, 0.3)
      border: $base-unit*0.1 solid rgba($colour-anakiwa-2, 0.45)
      border-radius: $base-unit*0.3
      margin-bottom: $base-unit*1.5
      padding: $base-unit*0.5 0
      margin-left: 0

  .slick
    &-list
      width: 100% !important

    &-dots
      position: relative
      bottom: 0

      .slick-active
        button
          border-radius: $base-unit*5
          background: $colour-anakiwa-2

          &::before
            content: ''

      li
        @include square(22px)
        border: $base-unit*0.1 solid $colour-anakiwa-2
        border-radius: $base-unit*5

        button::before
          content: ''

    &-slide:focus
      outline: none

@import 'apps-toolbar-sm-up'
@import 'apps-toolbar-lg-up'
@import 'apps-toolbar-xl-up'
