@media #{$breakpoint-lg-up}
  .footer-global
    .list
      li
        a
          &:focus
            text-decoration: underline
    a
      &:focus
        background-color: $colour-sweet-corn
        color: $colour-pickled-bluewood
        box-shadow: 0 0 0 ($base-unit * 0.3) $colour-sweet-corn
        text-decoration: underline
        outline: none
