.bs-tooltip
  &-top
    margin-top: -$base-unit*0.3
  
  &-bottom
    margin-top: $base-unit*0.3

  &-left
    margin-left: -$base-unit*1.2
  
  &-right
    margin-left: $base-unit*0.3