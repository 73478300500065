@media #{$breakpoint-lg-up}
  .list
    li
      a
        &:focus
          @include focus-box-shadow()

    &.list-horizontal--with-heading
      .list__content
        flex-direction: row

  .list-teasers
    margin-left: -$base-unit*3
