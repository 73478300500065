+margin-padding-utilities-standard
+margin-padding-utilities-breakpoints

// Fix bottom right
.fix-bottom-right,
.fix-bottom-left
  position: fixed
  bottom: 30px
  z-index: 999

.fix-bottom
  &-right
    margin-right: 0
    right: $base-unit*2
    bottom: $base-unit*2

  &-left
    margin-left: 0
    left: 20px
    bottom: 20px

@media #{$breakpoint-md-up}
  // Fix bottom right
  .fix-bottom-right
    right: 50%
    margin-right: -362px

  // Fix bottom left
  .fix-bottom-left
    left: 50%
    margin-left: -362px

@media #{$breakpoint-lg-up}
  .fix-bottom-right
    right: 50%
    margin-right: -470px

  // Fix bottom left
  .fix-bottom-left
    left: 50%
    margin-left: -470px

@media #{$breakpoint-xl-up}
  .fix-bottom-right
    right: 50%
    margin-right: -585px

  .fix-bottom-left
    left: 50%
    margin-left: -585px
