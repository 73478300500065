.x-scroll
  display: flex
  flex-wrap: nowrap
  overflow-x: scroll
  margin-bottom: 20px
  
  &::-webkit-scrollbar
    display: none

  &:before,
  &:after
    content: unset

  > div
    flex: 0 0 80%
    display: block
    margin: 0 $base-unit
    align-items: center
    justify-content: center

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

  &--full-width
    width: auto
    margin: 0 -#{$base-unit * 2}
    padding-bottom: ($base-unit*2)

    > div
      &:first-child
        margin-left: #{$base-unit * 2}

      &:last-child
        &::after
          content: ""
          width: 20px
          position: absolute
          left: 100%
          height: 1px
.snap
  scroll-snap-type: x mandatory

  > div
    scroll-snap-align: center

@import 'x-scroll-sm-up'