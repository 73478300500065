.homepage-events-wrapper
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between

  .box-event
    margin-right: $base-unit*2
    display: inline-block
    vertical-align: top
    width: 100%
    flex-basis: 100%
    -ms-flex-preferred-size: 93%

@import 'homepage-events-md-only'
@import 'homepage-events-lg-up'