$mega-menu-padding: $base-unit*2.5

@media #{$breakpoint-xl-up}
  .nav-global
    .navbar
      max-width: map-get($container-max-widths, "xl")

    &-links
      > .nav-item
        > .nav-link
          font-size: map-get($font-20, 'size')
          padding: $base-unit*1.5 $base-unit*2

          &.with-dropdown
            min-width: 100%

      &-dropdown
        margin: 0
