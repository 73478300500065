.facet
  border: $base-unit*0.1 solid $colour-grey-lighter
  margin-bottom: $base-unit*2

  &.facet-popup
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: $colour-white
    z-index: 8
    margin-bottom: 0
    border: 0

    form
      padding: 0

    .facet
      &-heading
        background: $colour-black

        .facet-title
          color: $colour-white
          line-height: map-get($font-17, 'line-height')

          .icon
            fill: $colour-white
            cursor: pointer

      &-body
        overflow-y: auto
        overflow-x: hidden
        position: absolute
        top: 54px
        left: 0
        right: 0
        bottom: 74px
        border: 0

        .facet-items
          padding: 0

          .facet-group-title
            display: inline-block
            width: 100%
            font-size: map-get($font-16, "size")
            line-height: map-get($font-16, "line-height")
            margin: 0
            white-space: normal
            border-bottom: $base-unit*0.1 solid $colour-grey-lighter

            a
              display: block
              text-indent: 0
              padding: $base-unit*2 0 $base-unit*2 $base-unit*2
              margin-bottom: 0

          .facet-item
            margin-bottom: 0
            border-bottom: $base-unit*0.1 solid $colour-grey-lighter
            white-space: nowrap
            padding-left: $base-unit*2

            label
              display: inline-block
              width: 100%
              padding: $base-unit*2 0 $base-unit*2 $base-unit*0.5
              margin: 0
              white-space: normal
              color: $colour-link
              cursor: pointer

              &:hover
                color: $colour-link-hover

              &:before,
              &::after
                display: none

            input[type="radio"],
            input[type="checkbox"]
              height: auto
              width: auto
              position: relative
              margin-left: 0
              opacity: 1
              display: inline-block

        .facet-actions
          position: fixed
          bottom: 0
          left: 0
          right: 0
          height: 74px
          text-align: center
          padding-top: $base-unit*2
          box-sizing: border-box

          button
            width: 40%

  &-type
    .facet-body
      .active
        background-color: $colour-grey-lightest

      .facet-items
        padding: 0

        .facet-item
          border-bottom: $base-unit*0.1 solid $colour-grey-lighter
          margin-bottom: 0

          a
            padding: $base-unit*1.5 $base-unit*3
            margin-bottom: 0
            color: $colour-grey-darker
            text-indent: -$base-unit*2

            &:hover
              text-decoration: underline

          .icon
            +square($icon-20)
            fill: $colour-black

  &-radio
    border: none
    margin-bottom: $base-unit*4

    .facet-heading
      background: none

      .facet-title
        padding: 0 0 $base-unit*0.6 0
        color: $colour-grey-darker
        font-family: $font-family-URW
        margin: 0

    .facet-body
      border: $base-unit*0.1 solid $colour-grey-lighter

      .facet-items
        padding: 0

        .active
          background-color: $colour-grey-lightest

        .facet-item
          border-bottom: $base-unit*0.1 solid $colour-grey-lighter
          margin-bottom: 0

          a
            padding: $base-unit*1.5 $base-unit*3
            color: $colour-grey-darker
            font-size: map-get($font-16, "size")
            line-height: map-get($font-16, "line-height")
            text-indent: -$base-unit*2
            margin-bottom: 0

            &:hover
              text-decoration: underline

          .icon
            display: inline-block
            margin-right: $base-unit*1.5

  &-heading
    background: $colour-grey-lightest

    a
      &:hover
        text-decoration: none

    .facet-title
      font-family: $font-family-standard
      font-size: map-get($font-20, 'size')
      line-height: map-get($font-20, 'line-height')
      display: block
      padding: $base-unit*1.5 $base-unit*2
      color: $colour-black

      .icon
        float: right
        margin-top: $base-unit*0.5
        fill: $colour-dusty-grey

  &-body
    .facet-items
      margin: 0
      list-style: none
      padding: $base-unit*1.5 $base-unit*2 $base-unit $base-unit*2

      .facet-item
        margin-bottom: $base-unit

        .icon
          @include square($icon-12)

      a
        @include indentation($icon-12 + $base-unit)
        display: block
        text-decoration: none
        font-size: map-get($font-17, "size")
        line-height: map-get($font-17, "line-height")

        &:hover
          text-decoration: underline

        .facet-item-title
          padding: $base-unit*1.5 $base-unit*3
          font-size: map-get($font-16, "size")
          line-height: map-get($font-16, "line-height")
          color: $colour-grey-darker
          text-indent: -$base-unit*2
          margin-bottom: 0

        .icon
          fill: $colour-dusty-grey
          margin: 0 $base-unit $base-unit*0.3 0

    .facet-show-more
      margin: 0 $base-unit*2 $base-unit*2 $base-unit*2
      display: block
      font-family: $font-family-light-obl
      font-style: italic

  form
    padding: $base-unit*1.5 $base-unit*2 $base-unit

    .control-group input[type="date"]
      width: 100%
      border: $base-unit*0.1 solid $colour-grey-nickel
      box-shadow: inset 0 $base-unit*0.1 $base-unit*0.1 rgba(0, 0, 0, 0.075)
      transition: border linear .2s, box-shadow linear .2s
      display: inline-block
      height: 34px
      padding: $base-unit*0.4 $base-unit*0.6
      margin-bottom: $base-unit*1.2
      font-size: map-get($font-17, 'size')
      line-height: map-get($font-17, "line-height")
      color: $colour-grey
      border-radius: $base-unit*0.4
      vertical-align: middle

.facet-filter-title
  font-size: map-get($font-20, 'size')
  line-height: map-get($font-20, 'line-height')
  padding: 0 0 $base-unit*0.6 0
  color: $colour-grey-darker
  font-family: $font-family-URW
  margin: 0

.selected-facets
  list-style: none
  margin: 0
  display: inline-block

  li
    margin: 0 $base-unit $base-unit 0
    float: left

    .icon
      @include square($icon-12)
      margin-bottom: $base-unit*0.4

    .btn
      text-align: left

.nav-facets
  margin-bottom: $base-unit*2.4
  margin-left: 0
  display: block

  .facet
    border: none

    a
      display: block

    .badge
      background-color: $colour-red
      float: right
      color: $colour-white

@import "facet-md-only"
@import "facet-lg-only"
