.footer-global
  @include fontantialiasing()
  margin: ($base-unit*2) 0 0 0
  padding: $base-unit*2 0
  background: $colour-pickled-bluewood
  color: $colour-tint-lightest

  .icon
    color: $colour-grey-light

  a
    color: $colour-tint-lightest
    border-bottom: ($base-unit*0.1) solid $colour-grey-lighter
    padding-bottom: ($base-unit*0.1)

    &:hover
      text-decoration: none
      color: $colour-grey-lighter
      border-bottom-color: $colour-grey-soft

  p
    a
      text-decoration: none

  .list
    li
      a
        text-decoration: none

  .footer-subtitle
    font-size: map-get($font-27, 'size')
    color: $colour-tint-lightest
    line-height: $base-unit*3
    margin-bottom: $base-unit

    a
      border: none

@import footer-global-sm-up
@import footer-global-lg-up
