$mega-menu-padding: $base-unit*2.5

@media (min-width: 1255px)
  .nav-global
    &-links
      > .nav-item
        &:first-child
          margin-left: $base-unit*1.5

      &-dropdown
        margin: 0 #{-$mega-menu-padding}
        padding: 0 0 $base-unit
