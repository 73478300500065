.tag
  @include border-radius($base-unit*0.2)
  display: block
  float: left
  background-color: $colour-grey-lightest
  border: $base-unit*0.1 solid $colour-grey-lighter
  margin: 0 $base-unit*0.5 $base-unit*0.5 0
  padding: 0 $base-unit*0.5

  a
    display: inline-block
    padding: 0 $base-unit*0.5
    margin: 0 #{-$base-unit*0.5}
    color: $colour-grey-darker

    &:hover
      text-decoration: none
      background-color: $colour-grey-nickel
      border-color: $colour-grey-nickel
