@media #{$breakpoint-lg-up}
  .download
    .download
      &-title
        a
          &:focus
            background-color: $colour-sweet-corn
            box-shadow: 0 0 0 #{$base-unit * 0.3} $colour-sweet-corn
            text-decoration: none
            color: $colour-link-hover
