@media #{$breakpoint-md-up}
  .modal
    &-dialog
      max-width: 570px
      
      &.modal-xl
        max-width: 1140px
      
      &.modal-lg
        max-width: 800px
      
      &.modal-sm
        max-width: 300px