@media #{$breakpoint-lg-up}
  .box
    a
      &:focus
        box-shadow: $focus-box-shadow

    &.box-feature
      .box-body
        .box-body-title
          &:focus
            color: $colour-smoky
