.circle
  border-radius: 50%
  width: $base-unit
  height: $base-unit
  float: left
  margin-right: $base-unit
  margin-top: ($base-unit / 2)
  text-align: center
  box-sizing: border-box
  font-family: $font-family-bold

  &--error,
  &--success,
  &--info
    color: $colour-white

  &--secondary
    background-color: $colour-tint-lighter
    color: $colour-grey-darker

    svg.icon
      fill: $colour-grey-darker

  &--success
    background-color: $colour-success

  &--info
    background-color: $colour-allports

  &--error
    background-color: $colour-red

  &--large
    width: $base-unit * 4
    height: $base-unit * 4

  &--medium
    width: $base-unit * 2
    height: $base-unit * 2

  &--small
    width: $base-unit * 1
    height: $base-unit * 1

  svg.icon
    fill: $colour-white
    padding-right: 0
    margin-top: 2px
