.toolbox
  width: 100%
  background-color: $colour-white
  padding: $base-unit*2 $base-unit $base-unit*3 $base-unit*2
  border-radius: $base-unit*0.5

  &-edit
    display: inline-block
    font-size: 1.125rem

    .icon
      +square($icon-20)

      fill: $colour-pickled-bluewood
      margin: 0 #{$base-unit} #{$base-unit*0.3} 0

  .toolbox
    &-links
      margin: 0 #{-$base-unit*0.75} $base-unit*0.5
      display: flex
      flex-wrap: wrap

    &-item
      padding: 0 $base-unit*0.75
      margin-bottom: $base-unit*2
      width: 100%

      &-name
        width: 100%

      a
        display: flex
        flex-shrink: 0
        color: $colour-grey-darker
        font-size: 1.125rem
        cursor: pointer
        text-decoration: none
        align-items: center

        .icon, path
          fill: $colour-white

        .icon
          +square(50px)
          padding: $base-unit*1.1
          margin-right: $base-unit
          border-radius: $base-unit*0.5
          background-color: $colour-boston-blue
          flex-shrink: 0
          transition: background $animation-duration-app-icons-remove $animation-app-icons-remove

        &:hover
          text-decoration: none

          .icon
            background-color: $colour-jelly-bean

      &.empty
        a:hover
          .icon
            path
              fill: $colour-white

        .icon
          background-color: rgba($colour-jelly-bean, 0.5)
          padding: $base-unit*1.5

          path
            fill: $colour-lochinvar

@import 'toolbox-380-up'
@import 'toolbox-md-up'
@import 'toolbox-xl-up'
