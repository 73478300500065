.accordion
  margin-bottom: $base-unit * 2.4

  &-section
    @extend %no-border

    border-bottom: ($base-unit * 0.2) solid $colour-grey-lighter

    &:last-child
      border-bottom: none

    &-header
      cursor: pointer
      position: relative

      &-title
        display: block
        margin-top: 0
        margin-bottom: 0
        font-family: $font-family-standard
        font-size: map-get($font-22, 'size')
        line-height: map-get($font-22, 'line-height')
        padding: #{$base-unit * 0.8} #{$base-unit * 1.5}
        color: $colour-text
        text-decoration: none
        text-indent: -$base-unit * 3
        padding-left: $base-unit * 4.1

        .icon
          @extend %rotate-0
          @extend %transition

          display: inline-block
          fill: $colour-grey-light
          line-height: map-get($font-17, 'line-height')
          margin-right: $base-unit * 1

        &:hover
          text-decoration: none
          color: $colour-text

        &:not(.collapsed)
          .icon
            @extend %rotate-90

            fill: $colour-red

  &-inner
    border-top: none
    padding: #{$base-unit - 1} #{$base-unit * 1.5}

    h3
      font-size: map-get($font-22, 'size')
      line-height: map-get($font-22, 'line-height')
      margin-bottom: 12px

    h4
      font-size: map-get($font-18, 'size')
      line-height: map-get($font-18, 'line-height')
      margin-bottom: 12px

  &-category
    margin: 0
    margin-bottom: $base-unit*0.5
    color: $colour-tapa
    display: block

  &-large
    .accordion-section-header
      .accordion-teaser,
      .accordion-section-header-supplement,
      .accordion-category
        padding-left: $base-unit * 4.1

      &-title
        font-family: $font-family-bold
        color: $colour-link

      &-supplement
        font-size: map-get($font-14, 'size')
        color: $colour-grey-darker

  &--link
    .accordion-section-header-title
      color: $colour-link

      &:hover
        color: $colour-link-hover

  &--sm
    .accordion-section-header-title
      display: flex
      font-size: map-get($font-17, 'size')
      line-height: map-get($font-17, 'line-height')
      padding: 0
      text-indent: 0
      margin-bottom: $base-unit * 0.5

      .icon
        +square($icon-16)

        margin-top: $base-unit * 0.5
        margin-right: $base-unit

  &-mobile
    width: 100%

    .accordion-section
      border-bottom: ($base-unit * 0.1) solid $colour-grey-lighter

      &-header
        &-title
          padding: #{$base-unit * 0.8} #{$base-unit * 1.5} #{$base-unit * 0.8} #{$base-unit * 4.7}
          text-indent: -$base-unit * 3.2

      .accordion-toggle
        display: flex

@import accordion-md-up
@import accordion-lg-up
