$border-lg-width: 2px

.border
  &-grey-light
    border-color: $colour-alto !important

  &-lg
    border: $border-lg-width solid $colour-alto !important

    &-top
      border-top: $border-lg-width solid $colour-alto !important

    &-right
      border-right: $border-lg-width solid $colour-alto !important

    &-bottom
      border-bottom: $border-lg-width solid $colour-alto !important

    &-left
      border-left: $border-lg-width solid $colour-alto !important
