$appToolbarItemWidth: 120px

@media #{$breakpoint-sm-up}
  .apps-toolbar
    padding: 0 $base-unit*4.5 $base-unit*4

    h1
      margin-top: $base-unit*6

    .apps-toolbar
      &-icon
        margin: 0
        position: absolute
        top: 35px
        right: 0
        left: 0

        .icon
          @include square($icon-50)
          fill: $colour-black

      &-selected
        margin: $base-unit*4 0 $base-unit*9

        &-item
          display: inline-block
          position: relative
          background: none
          width: $appToolbarItemWidth
          padding: 0

          &-text
            color: $colour-white

          &,
          &-empty
            margin: 0 $base-unit*2.75 $base-unit*3

          p
            flex-basis: unset
            text-align: unset

          &-dragdrop
            display: none

          &-circle,
          &-empty
            @include square($appToolbarItemWidth)
            border-radius: $base-unit*0.4

          &-circle
            background-color: $colour-anakiwa-2
            margin-bottom: $base-unit*1.5

          &-remove
            @include square(26px)
            position: absolute
            top: -10px
            right: -10px

            &::before
              line-height: 1.2rem

          &-empty
            border: $base-unit*0.1 dashed $colour-anakiwa-2
            display: flex
            justify-content: center
            align-items: center

            .icon
              @include square($icon-20)
              margin: 0

              path
                fill: $colour-white

        .ui-sortable
          display: flex
          flex-wrap: wrap
          justify-content: flex-start
          margin: 0

      &-slider
        .apps-toolbar-icon
          .icon
            fill: $colour-white

        &-options
          text-align: left
          margin-bottom: $base-unit*2

          .apps-toolbar-select-wrapper,
          form
            display: inline-block
            width: 297px
            margin-bottom: $base-unit*3

        &-item
          flex-direction: column
          flex-basis: 20%
          border: 0

          .icon
            @include square(70px)
            margin: 0

          &.active
            .apps-toolbar-slider-item-icon
              border-color: $colour-red

              &:before
                position: relative
                top: -2px

          &-icon
            display: inline-block
            margin: 0
            border: $base-unit*0.1 solid $colour-white
            background: $colour-transparent

            &::before
              @include square(25px)
              border-radius: 100%
              color: white

          .apps-toolbar-icon
            position: unset
            margin-bottom: $base-unit*0.5
