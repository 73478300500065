@use "sass:math"

.quote
  border: ($base-unit*0.2) solid $colour-grey-lighter
  border-left: 0
  border-right: 0
  margin: 0 0 ($base-unit*2) 0
  padding: #{($base-unit*2)} #{($base-unit*4)}

  .quote-body
    font-family: $font-family-light-obl
    font-size: map-get($font-20, 'size')
    border-left: none
    margin: 0 0 $base-unit 0
    padding: 0

    p
      font-size: map-get($font-19, 'size')
      line-height: 1.5625rem
      margin-bottom: inherit

  .quote-author
    color: $colour-red

  &.with-icon
    .icon
      @include square($icon-24)
      fill: $colour-tint-dark
      margin-bottom: math.div($base-unit, 2)

    .quote-body
      border-left: none

  &.quote-vertical
    text-align: center

    &.with-image
      padding-left: ($base-unit*6)

      .quote-image
        float: none
        margin: 0 auto $base-unit
        display: block

  &.with-image
    padding-left: ($base-unit*15)
    overflow: hidden

    .quote-image
      float: left
      margin-left: (-($base-unit*13))
      margin-top: math.div($base-unit, 2)
      width: $base-unit*10

.float-right,
.float-left
  .quote
    padding: ($base-unit*2)
    border-right: 0
    border-left: 0

@import 'quote-xs-up'
