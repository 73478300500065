@media #{$breakpoint-md-up}
  .carousel
    .carousel
      &-inner
        display: block
        overflow: hidden
        width: auto
        margin: 0 auto

      &-item
        position: relative
        display: none
        float: left
        width: 100%
        margin: 0 -100% 0 0
        backface-visibility: hidden
        transition: transform .6s ease-in-out

        &:first-child
          margin-left: 0
        
        &:last-child
          padding-right: 0
          flex-basis: 80%

        /* Transitions */
        &-next,
        &.active,
        &-prev
          display: block

      &-control
        &-prev,
        &-next
          @include card-control-item()
          display: flex        

      &-indicators
        display: flex
        opacity: 0

        &-container
          overflow: auto
          padding-bottom: $base-unit

        &.with-thumbnails
          opacity: 1
          position: relative
          justify-content: start
          list-style: none
          margin: 0 0 $base-unit 0
          overflow-x: auto
          padding-bottom: $base-unit * 1.5

          li
            margin: 0 $base-unit 0 0
            cursor: pointer
            opacity: 0.5
            height: 50px
            width: auto
            text-indent: 0
            border: none
            flex: 0 0 88px 

            img
              border: $base-unit*0.1 solid $colour-grey-light

            &.active
              opacity: 1
            &:last-child
              margin-right: 0