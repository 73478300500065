@media #{$breakpoint-lg-up}
  .carousel
    .carousel
      &-control
        &-next,
        &-prev
          &:focus
            background-color: transparent

            &:not(.disabled)
              .icon
                box-shadow: 0 0 0 #{$base-unit * 0.3} $colour-sweet-corn
