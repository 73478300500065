@use "sass:math"

.icon
  @include square($icon-20)
  fill: $colour-grey-darker
  margin-right: math.div($base-unit, 2)

  &-xs
    @include square($icon-12)

  &-md
    @include square($icon-24)

  &-lg
    @include square($icon-32)

  &-xl
    @include square($icon-48)

  &-success
    fill: $colour-success

  &-error
    fill: $colour-red

  &-info
    fill: $colour-success

  &-tile
    @include square($icon-24)
    fill: $colour-white
    margin-right: $base-unit

  &.loading
    @include square(50px)
    stroke: $colour-grey-darker

    .loading-circle
      stroke: $colour-grey-light

  &-personalisation
    position: absolute
    top: -5px
    right: 9px

    .icon
      width: 23px
      height: 28px

      &-small
        @include square(($base-unit*2))
