/* Specific sizings */
$icon-10: 10px
$icon-12: 0.75rem
$icon-13: 13px
$icon-14: 14px
$icon-15: 15px
$icon-16: 16px
$icon-18: 18px
$icon-20: 20px
$icon-24: 24px
$icon-30: 30px
$icon-32: 32px
$icon-40: 40px
$icon-48: 48px
$icon-50: 50px
$icon-60: 60px
