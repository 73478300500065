@use "sass:math"

.well
  padding: $base-unit*2
  border: math.div($base-unit, 10) solid $colour-grey-light
  background-color: $colour-wild-sand-grey
  margin-bottom: $base-unit*2
  min-height: 20px
  box-shadow: inset 0 math.div($base-unit, 10) math.div($base-unit, 10) rgba($colour-black, 0.05)

  &.well-small
    padding: $base-unit

  &.well-large
    padding: $base-unit*2.5

    .well-section
      @include flex(1 1 100%)
      padding: $base-unit*2

      &:first-child
        margin-right: $base-unit

  h1,h2,h3,h4,h5,h6
    color: $colour-black
    margin: $base-unit*1.2 0
    text-rendering: optimizeLegibility

@import 'well-sm-up'
@import 'well-lg-up'
@import 'well-xl-up'
