$table-bottom-margin: $base-unit*2.4

.table
  margin-bottom: $table-bottom-margin

  th
    font-family: $font-family-bold
    font-weight: $font-weight-standard

  thead
    th
      border-bottom-width: $base-unit*0.1

  &-caption
    font-family: $font-family-light-obl
    padding: 0 0 $base-unit 0
    text-align: center
    caption-side: top

  th, td
    border-top: $base-unit*0.1 solid $colour-grey-light
    text-align: left

  &:not(.table-bordered)
    thead
      th
        border-top: none

  &-sortable
    thead
      th
        cursor: pointer

        &:focus
          outline: $base-unit*0.3 solid $colour-sweet-corn

  &-responsive
    margin: -$base-unit*0.3
    padding: $base-unit*0.3
    width: calc(100% + #{$base-unit*0.3 * 2})

.tablesorter
  &-headerUnSorted,
  &-headerDesc,
  &-headerAsc
    background-repeat: no-repeat
    background-position: $base-unit 50%
    padding-left: $base-unit*3.5 !important
    background-size: $base-unit*1.5

  &-headerUnSorted
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20class%3D%27icon%27%20version%3D%271.1%27%20id%3D%27Layer_1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20viewBox%3D%270%200%20768%20767%27%20width%3D%2220%22%20height%3D%2220%22%20style%3D%27enable-background%3Anew%200%200%20768%20767%3B%27%20xml%3Aspace%3D%27preserve%27%3E%3Cpath%20d%3D%27M768%2C512L545%2C767L320%2C512h158V257h129v255H768z%20M448%2C256L225%2C1L0%2C256h158v255h129V256H448z%27%2F%3E%3C%2Fsvg%3E")

  &-headerDesc
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20class%3D%27icon%27%20version%3D%271.1%27%20id%3D%27Layer_1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20viewBox%3D%270%200%20896%20767%27%20width%3D%2720%27%20height%3D%2720%27%20style%3D%27enable-background%3Anew%200%200%20896%20767%3B%27%20xml%3Aspace%3D%27preserve%27%3E%3Cpath%20d%3D%27M448%2C512L225%2C767L0%2C512h158V257h129v255H448z%20M384%2C448h256v-64H384V448z%20M384%2C256h384v-64H384V256z%20M896%2C0v64H384V0H896z%27%2F%3E%3C%2Fsvg%3E")

  &-headerAsc
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%271.1%27%20id%3D%27Layer_1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20viewBox%3D%270%200%20896%20767%27%20width%3D%2720%27%20height%3D%2720%27%20style%3D%27enable-background%3A%20new%200%200%20896%20767%3B%27%20xml%3Aspace%3D%27preserve%27%3E%3Cpath%20d%3D%27M448.5%2C255L224.5%2C0L0.5%2C255h158v256h129V255H448.5z%20M384.5%2C319h256v64h-256V319z%20M384.5%2C511h384v64h-384V511z%20M896.5%2C767v-64h-512v64H896.5z%27%2F%3E%3C%2Fsvg%3E")

.os-host-overflow-x
  .table
    margin-bottom: calc(#{$table-bottom-margin * 2} + 6px)

    .table-caption
      position: sticky
      left: 0
