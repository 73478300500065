.scroll-bar
  overflow-x: scroll

  &::-webkit-scrollbar
    width: 16px

  &::-webkit-scrollbar-track
    background-color: #e4e4e4
    border-radius: 100px

  &::-webkit-scrollbar-thumb
    background-color: #d4aa70
    border-radius: 100px

  &__top
    height: 0.1px
