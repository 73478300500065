@media #{$breakpoint-lg-only}
  .header-global
    padding: 0

    &-user
      right: 110px

    form
      .search-form
        margin-right: $base-unit*10

        &-container
          width: 240px