@media #{$breakpoint-md-up}
  .toolbox
    padding: $base-unit*3.5 $base-unit*4 0
    border-radius: $base-unit
    box-shadow: 0 0 $base-unit 0 rgba($colour-black, 0.1)

    .toolbox
      &-links
        margin: 0 #{-$base-unit*2.5}
        padding-bottom: $base-unit*0.5

      &-item
        width: 25%
        flex-basis: 25%
        margin-bottom: $base-unit*3
        padding: 0

        a
          flex-direction: column
          text-align: center
          height: unset
          margin: 0 auto
          width: 120px

          .icon
            @include square(120px)
            padding: $base-unit*2.5
            margin: 0 0 $base-unit
            align-self: center

        &.empty
          .icon
            padding: $base-unit*5

    &-edit
      margin: $base-unit*2.5 0 0
