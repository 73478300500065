.box
  @include border-radius(0)
  @include clearfix()
  padding: 0
  margin-bottom: $base-unit*2
  display: block

  &.fill-height
    height: calc(100% - #{$base-unit*2})

  &-header
    @include clearfix()
    margin-top: 0
    margin-bottom: $base-unit

    & + .box-body
      padding: 0

    .box-title
      padding: $base-unit*2 0 0 0
      font-family: $font-family-URW
      font-size: map-get($font-29, 'size')
      line-height: map-get($font-29, 'line-height')
      margin-bottom: $base-unit*0.5
      color: $colour-red

      a
        &:after
          content: ''

  &:first-child:not(.box-event)
    border-top: none

    .box-title
      padding: 0

    .icon
      margin-top: -$base-unit*0.2

  &.with-icon
    .icon
      @include square(24px)
      float: left
      position: relative
      top: $base-unit*2.3
      left: 0
      margin-right: $base-unit*0.2
      fill: $colour-red
      padding-right: $base-unit*0.5

    .box-title
      margin-left: $base-unit*2.4

  &-body
    padding: 0

    h3
      margin-bottom: $base-unit*1.6

    p:last-of-type
      margin-bottom: 0

    table:last-of-type
      margin-bottom: 0

    .list,
    .list-links
      margin-bottom: 0

    .form-actions
      padding: 0
      background-color: transparent
      border-top: none
      margin-top: 0

    .form-inline,
    .form-horizontal
      margin-bottom: 0

      input,
      textarea,
      select
        margin-bottom: $base-unit

    .lead
      font-family: $font-family-light-obl
      font-style: italic
      font-size: map-get($font-20, 'size')
      line-height: map-get($font-17, 'line-height')

  .box-footer
    & > *
      margin-bottom: 0

    a
      font-family: $font-family-light-obl

      &.btn
        font-family: $font-family-base
        font-style: normal

        &:after
          content: ''
          margin-left: 0

  &-event
    padding: $base-unit*1.5
    background-color: $colour-white
    border: $base-unit*0.1 solid $colour-mercury
    margin-bottom: $base-unit*2
    margin-right: 0
    position: relative

    &-date
      display: inline-block
      width: 40px
      color: $colour-true-black
      text-align: center
      vertical-align: top

      & > time
        display: block
        line-height: 1

      &-day
        font-family: $font-family-bold
        font-size: 2.375rem

      &-month
        font-size: map-get($font-20, 'size')
        margin-bottom: $base-unit*0.5

      &-year
        font-size: map-get($font-15, 'size')

    .box-body
      display: inline-block
      width: calc(100% - 60px)
      margin-left: $base-unit*1.5
      vertical-align: top

      .box-title
        font-family: $font-family-URW
        font-size: map-get($font-18, 'size')
        line-height: map-get($font-18, 'line-height')
        display: block
        margin-bottom: $base-unit*0.5
        word-break: break-word
        overflow: hidden

        a
          color: $colour-science-blue

          &:hover
            color: $colour-link-hover

      .list
        margin-bottom: 0

        .icon
          margin-top: -$base-unit*0.4

      &-location,
      &-time
        color: $colour-true-black
        text-indent: -$base-unit*2.4
        padding-left: $base-unit*2.4

        a,
        span
          font-size: map-get($font-14, 'size')
          text-decoration: none

        a
          color: $colour-science-blue

          &:hover
            text-decoration: underline
            color: $colour-link-hover

  &.box-files
    .list-icons
      li
        margin-bottom: $base-unit*0.5

      .icon
        fill: $colour-red

  &-inverse
    background: $colour-gallery
    display: none
    padding: $base-unit*3 $base-unit*2 $base-unit*3.5

    .box-header
      .box-title
        font-size: map-get($font-24, 'size')
        line-height: map-get($font-24, 'line-height')
        font-family: $font-family-URW
        letter-spacing: 0

    .box-body
      font-size: 1.125rem
      line-height: 1.625rem

  &.box-calltoaction
    border-top: $base-unit*0.3 solid $colour-grey-lighter

    &.with-image
      border-top: none

      img
        margin-bottom: $base-unit * 1.5

    &.with-icon
      .box-header
        .box-title
          margin-left: 0

        .icon
          @include square($icon-24)
          margin-top: 0
          float: right

        .icon-siarad-cymraeg
          color: $colour-ecstasy

    .box-footer
      margin-top: $base-unit

  &-feature
    background-color: $colour-smoky
    position: relative
    padding: $base-unit*2.5 $base-unit*3 $base-unit*3
    border-top: 0

    .box-header
      margin-bottom: $base-unit*0.3

      .icon
        @include square(20px)
        vertical-align: text-top

        path
          fill: $colour-white
          cursor: default

      .box-title
        font-family: $font-family-URW
        display: inline-block
        font-size: map-get($font-18, 'size')
        line-height: map-get($font-18, 'line-height')
        color: $colour-white
        padding: 0

        &:hover
          color: white

    .box-body
      color: $colour-white
      font-size: map-get($font-15, 'size')
      line-height: map-get($font-17, 'line-height')

      .box-body
        &-title
          font-family: $font-family-URW
          color: $colour-white
          font-size: 1.875rem
          line-height: 2.125rem
          margin-bottom: $base-unit*1.5

      a:hover
        color: $colour-white

@import 'box-md-up'
@import 'box-lg-up'
