.process
  &-steps
    padding: 0
    margin: 0
    list-style-type: none

    .process-step-title
      &:visited
        color: $colour-white

      &:focus
        background-color: $colour-sweet-corn
        color: $colour-tint-darkest
        box-shadow: none

        .process-arrow-fill
          fill: $colour-sweet-corn

  &-step
    flex-flow: column
    margin-bottom: $base-unit * 3
    font-family: $font-family-bold
    font-size: map-get($font-15, 'size')

    &:first-child
      .process-step-list
        margin-left: 0

    &:last-child
      .process
        .process-step-title
          svg
            display: none

    &-item-link
      &:visited
        text-decoration: none
        color: $colour-white

    &-list
      padding: 0
      margin: 0
      list-style-type: none
      margin-left: $base-unit * 0.75

      &:hover,
      &:focus
        text-decoration: none
        color: $colour-red

      &:visited
        text-decoration: none
        color: $colour-white

      .process-step-item
        text-align: center
        display: block
        margin-bottom: $base-unit * 0.75
        background-color: $colour-grey-light
        color: $colour-grey-darker

        a
          padding: $base-unit * 1.5
          display: block
          text-decoration: underline
          color: $colour-grey-darker

          &:hover
            background-color: $colour-gainsboro
            text-decoration: none
            color: $colour-grey-darker

          &:visited
            text-decoration: none
            color: $colour-grey-darker

          &:focus
            background-color: $colour-sweet-corn

    &.active
      .process-step-title
        background-color: $colour-tint-darkest

        &:focus
          color: $colour-tint-darkest
          background-color: $colour-sweet-corn

          .process-arrow-fill
            fill: $colour-sweet-corn

        &:after
          border-color: $colour-tint-darkest $colour-transparent

        .process-arrow-fill
          fill: $colour-tint-darkest

    &-title
      padding: $base-unit * 1.5 $base-unit * 2.25
      margin-bottom: $base-unit * 0.75
      position: relative
      display: block
      background-color: $colour-tint-darker
      color: $colour-white
      width: 100%
      height: auto
      box-sizing: border-box

      &:after
        position: absolute
        top: 100%
        left: 47%
        width: 0
        height: 0
        border-width: $base-unit * 1.5 $base-unit * 1.5 0 $base-unit * 1.5
        border-color: $colour-tint-darker $colour-transparent
        border-style: solid
        content: ""

      &:hover
        background-color: $colour-tint-darkest

        .process-arrow-fill
          fill: $colour-tint-darkest

      svg
        display: none
        position: absolute
        top: 0
        left: 100%
        z-index: 1
        bottom: 0
        height: 100%
        width: 39px
        margin-left: -$base-unit*0.1

      .process-arrow-fill
        fill: $colour-tint-darker

      &:hover,
      &:focus,
      &:active
        color: $colour-white
        text-decoration: none

        &:after
          border-color: $colour-tint-darkest $colour-transparent

      &:visited
        color: $colour-white

  &.with-list
    .process-step
      display: block
      background-color: initial

      &-title
        margin-bottom: $base-unit * 0.75

      &-list
        padding: 0
        margin: 0
        list-style-type: none
        display: block

        &:hover
          background-color: initial

@import process-md-up
