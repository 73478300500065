@media #{$breakpoint-md-up}
  .box
    &.box-calltoaction
      &.with-icon
        .box-header
          .icon
            @include square($icon-48)

    &.box-inverse
      display: block

    &.box-feature
      &-large
        .box-body
          .box-body
            &-title
              font-size: map-get($font-28, 'size')
              line-height: map-get($font-28, 'line-height')
