@media #{$breakpoint-lg-up}
  .apps-toolbar
    .apps-toolbar
      &-icon-close
        right: 25px

        &:focus
          outline: none
          background-color: $colour-sweet-corn

          .icon
            fill: $colour-black

      &-selected-item-remove
        &:focus
          background-color: $colour-sweet-corn
          box-shadow: none

          &::before
            color: $colour-black

      &-slider
        &-options
          margin-bottom: $base-unit*7.5

          select,
          input
            &:focus
              outline: $base-unit*0.3 solid $colour-sweet-corn
              outline-offset: $base-unit*0.05

          .clear,
          .search,
          &:focus,
          &:focus:hover
            outline: none
            background-color: $colour-sweet-corn

            .icon
              fill: $colour-black

        &-item
          .apps-toolbar-slider-item-icon
            &:not(.disabled):focus
              background-color: $colour-sweet-corn
              border-color: $colour-sweet-corn
              box-shadow: none

              &::before
                color: $colour-black

            &.disabled:focus
              box-shadow: none
