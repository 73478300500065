.header-global
  background-color: $colour-pickled-bluewood
  z-index: $zindex-modal-backdrop + 10
  position: relative

  &-mobile
    &-search
      background-color: $colour-wild-sand
      padding: 0 $base-unit*2.7
      z-index: $zindex-modal-backdrop + 10
      position: relative

      .search
        &-form
          &-container
            margin: 0

      form
        margin-bottom: 0
        padding-bottom: $base-unit*6

      &-title
        font-size: map-get($font-30, 'size')
        font-family: $font-family-URW
        color: $colour-black
        margin: 0 0 $base-unit*1.3
        padding: $base-unit*5 0 0

  &-container
    height: $mobile-header-height

    .search
      &-form
        display: none

  .menu-open,
  .search-open
    background-color: $colour-comet

  &-control
    padding: $base-unit*2
    width: 70px
    border-left: $base-unit*0.1 solid $colour-white
    cursor: pointer

    .icon
      @include square($icon-32)
      fill: $colour-white
      margin: auto

    &-menu
      .icon
        .bar
          &:nth-child(4),
          &:nth-child(2)
            transform: rotate(0)
            transition: transform $animation-duration-short $animation-mobile-menu-burger $animation-delay-mobile-menu-burger-middle

          &:nth-child(3)
            transform: scale(1)
            transition: transform $animation-duration-mobile-menu-burger-middle $animation-cubic-standard $animation-delay-mobile-menu-burger-middle

      &.menu-open
        .icon
          .bar
            &:nth-child(3)
              transform: scale(0)

            &:nth-child(4)
              transform: rotate(-45deg) translate(-11px, -2px)

            &:nth-child(2)
              transform: rotate(45deg) translate(2px, -5px)

  &-brand
    display: flex
    align-items: center
    font-family: $font-family-URW
    color: $colour-white
    font-size: map-get($font-20, "size")
    text-decoration: none
    padding: $base-unit*1.5

    &:hover
      text-decoration: none
      color: $colour-white

    svg
      @include square($icon-50)
      flex-shrink: 0
      margin-right: $base-unit

  .search-form
    .search-form
      &-query,
      &-clear
        border-radius: 0

      &-query
        border: $base-unit*0.1 solid $colour-dusty-grey

      &-clear
        margin: $base-unit*0.1 $base-unit*0.1 $base-unit*0.1 0

@import "header-global-sm-up"
@import "header-global-md-up"
@import "header-global-lg-up"
@import "header-global-lg-only"
@import 'header-global-xl-up'
