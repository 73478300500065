@use "sass:math"

$download-image-width: 100px

.download
  display: grid
  grid-template-areas: 'download-category' 'download-title' 'download-date' 'download-description' 'download-actions' 'download-requests'
  margin: 0 0 ($base-unit * 2) 0
  padding: 0

  &.with-image
    grid-template-areas: 'download-image download-category' 'download-image download-title' 'download-image download-date' 'download-image download-description' 'download-image download-actions' 'download-image download-requests'
    grid-template-columns: $download-image-width 1fr
    grid-column-gap: $base-unit * 2

    .download
      &-category,
      &-title,
      &-date,
      &-actions,
      &-description,
      &-requests
        @supports not (display: grid)
          margin-left: $download-image-width + 20px

  .download
    &-image
      grid-area: download-image

      +grid-ms-fallback(1, 1)

      -ms-grid-row-span: 5
      margin: 0
      padding: 0

      @supports not (display: grid)
        float: left
        width: $download-image-width
        margin-right: $base-unit * 2

      img
        width: 100px

    &-category
      +grid-ms-fallback(3, 1)

      grid-area: download-category
      margin-bottom: 0
      display: flex
      align-items: center

      .icon
        margin-right: math.div($base-unit, 2)

    &-title
      +grid-ms-fallback(3, 2)

      grid-area: download-title
      margin-bottom: 0
      font-family: $font-family-bold
      font-size: map-get($font-24, 'size')
      line-height: map-get($font-24, 'line-height')

      a
        color: $colour-red

        &:hover
          color: $colour-link-hover

    &-date
      +grid-ms-fallback(3, 3)

      display: block
      grid-area: download-date

    &-description
      +grid-ms-fallback(3, 4)

      grid-area: download-description

      @supports not (display: grid)
        margin-left: $download-image-width + 20px

    &-actions
      +grid-ms-fallback(3, 5)

      grid-area: download-actions
      margin-bottom: 0

      .btn
        margin-right: $base-unit
        margin-bottom: $base-unit * 1.5
        display: inline-block

        &:last-child
          margin-right: 0

    &-requests
      +grid-ms-fallback(3, 6)

      grid-area: download-requests

    &-filesize
      font-family: $font-family-standard

    &-link,
    &-description,
    &-date
      margin-bottom: $base-unit * 1.5

@import download-lg-up
