.f
  &-fg-med
    font-family: $font-family-bold !important

  &-fg-book
    font-family: $font-family-standard !important

.text
  &-underline
    text-decoration: underline !important

  &-xs
    font-size: map-get($font-13, 'size') !important
    line-height: map-get($font-13, 'line-height') !important

  &-sm
    font-size: map-get($font-15, 'size') !important
    line-height: map-get($font-15, 'line-height') !important

  &-md
    font-size: map-get($font-19, 'size') !important
    line-height: map-get($font-19, 'line-height') !important

  &-normal
    font-size: map-get($font-17, 'size') !important
    line-height: map-get($font-17, 'line-height') !important

  &-no-truncate
    text-overflow: clip !important
    overflow: visible !important

  &-tuatara
    color: $colour-grey-darker !important

  &-emperor
    color: $colour-grey !important

  &-dove
    color: $colour-grey-darkest !important

  &-red
    color: $colour-red !important
