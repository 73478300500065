.carousel
  &-indicators
    display: none

  &-control
    &-prev,
    &-next
      display: none
      width: auto

    &-next
      margin-right: $base-unit * 2

    &-prev
      margin-left: $base-unit * 2

  &-caption
    position: relative
    left: 0
    right: 0
    bottom: 0
    padding: $base-unit #{$base-unit * 5.7} $base-unit #{($base-unit * 1.5)}
    background-color: $colour-tint-lightest
    text-align: left
    border: $base-unit*0.1 solid $colour-grey-light
    border-top: none   

    p
      &:last-child
        margin-bottom: 0

  &-inner
    @extend .x-scroll
    @extend .snap
    @extend .x-scroll--full-width

  &-item
    position: relative

    figure
      margin: 0

    img
      @extend .w-100
      border: $base-unit*0.1 solid $colour-grey-light
      border-bottom: none  

  &-pagination
    position: absolute
    right: 1px
    text-align: right
    padding: $base-unit
    margin-bottom: 0
    background-color: $colour-tint-lightest
    z-index: 11

@import 'carousel-md-up'
@import 'carousel-lg-up'
