@use 'sass:math'

$container-max-widths: ("sm": 100%, "md": 744px, "lg": 960px, "xl": 1190px)
$grid-breakpoints: ("xs": 0, "sm": 480px, "md": 768px, "lg": 980px, "xl": 1200px)

/* Font config */
$font-family-base: $font-family-standard
$font-family-sans-serif: $font-family-standard
$lead-font-size: map-get($font-22, "size")
$font-size-base: map-get($font-17, "size")
$lead-font-weight: 400
$body-color: $colour-grey-darker
$link-color: $colour-link

/* Font styling */
$font-weight-bold: 400
$font-weight-bolder: 400

/* Alert styling */
$alert-border-radius: 0
$alert-border-width: 0
$alert-padding-y: 0.625rem
$alert-padding-x: 0.9375rem

/* Alert configuration */
$alert-colors: "warning" $colour-alert-warning-bg transparent $colour-grey-darker, "error" $colour-red transparent $colour-white, "info" $colour-info transparent $colour-white, "success" $colour-success transparent $colour-white

/* Carousel */
$carousel-caption-color: $colour-text

/* Pagination */
$pagination-color: $colour-grey
$pagination-hover-color: $colour-grey-darker
$pagination-hover-bg: $colour-grey-light
$pagination-active-bg: $colour-black
$pagination-border-width: 0
$pagination-padding-y: 0
$pagination-padding-x: 0.5rem
$pagination-line-height: 1.875rem

/*Table*/
$table-cell-padding: $base-unit*0.8
$table-border-color: $colour-grey-light
$table-border-width: $base-unit*0.1
$table-caption-color: $colour-grey-darker
$table-accent-bg: $colour-grey-lighter
$table-hover-bg: $colour-wild-sand-grey

/* Pills */
$nav-pills-border-radius: 3px
$nav-pills-link-active-color: $colour-white
$nav-pills-link-active-bg: $colour-btn-link

/* Tooltip */
$tooltip-font-size: map-get($font-15, 'size')
$tooltip-bg: $colour-black
$tooltip-padding-x: $base-unit
$tooltip-padding-y: $base-unit*0.5
$tooltip-border-radius: 0
$tooltip-opacity: 0.8
$tooltip-arrow-height: $base-unit*0.5

/* Form */
$input-border-color: $colour-grey-darker
$input-border-width: $base-unit*0.2
$input-border-radius: $base-unit*0.4
$input-padding-y: 6.75px
$input-padding-x: 0.9375rem
$input-padding-y-sm: 5px
$input-padding-x-sm: 5px
$input-padding-y-lg: 5px
$input-padding-x-lg: 5px

/* Form states */
$form-feedback-margin-top: 0
$form-feedback-font-size: 1rem
$form-feedback-valid-color: $colour-grey-darker
$form-feedback-invalid-color: $colour-grey-darker

$form-feedback-icon-valid-color: $colour-transparent
$form-feedback-icon-valid: ""
$form-feedback-icon-invalid-color: $colour-transparent
$form-feedback-icon-invalid: ''

/* breadcrumb */
$breadcrumb-padding-y: #{$base-unit*1.5}
$breadcrumb-padding-x: 0
$breadcrumb-divider: none
$breadcrumb-bg: $colour-white
$breadcrumb-active-color: $colour-grey-darker
$breadcrumb-border-radius: 0

/* Progress-bar */
$progress-border-radius: 0
$progress-height: 1.5rem
$progress-bg: $colour-progress-bg

/* Progress-bar-bg-colour */
$progress-bar-bg: $colour-cobalt
$success: $colour-green-haze
$info: $colour-cobalt
$warning: $colour-orange-peel
$danger: $colour-red

//Modal 
$modal-content-border-width: $base-unit * 0.1
$modal-content-border-radius: 0
// $modal-header-padding             : #{($base-unit * 2)}
$modal-header-border-width: $base-unit * 0.1
$modal-header-border-color: $colour-grey-light
$modal-title-line-height: $base-unit * 3
$modal-header-padding: #{($base-unit * 0.9)} #{($base-unit * 1.5)}
$modal-footer-background: $colour-grey-lightest
$modal-backdrop-bg: $colour-black
$modal-backdrop-opacity: 0.7
$modal-md: 90%

$hr-border-color: $colour-grey-light
$hr-margin-y: $base-unit*2

$custom-select-padding-y: 0.5rem
$custom-select-padding-x: 0.5rem

/* Division overrides */
$spacer: 1rem
$headings-margin-bottom: math.div($spacer, 2)
$navbar-padding-y: math.div($spacer, 2)
$nav-divider-margin-y: math.div($spacer, 2)
