@media #{$breakpoint-xl-up}
  .dictionary-terms
    @include content-columns(4, ($base-unit*2))

    li a:focus
      background-color: $colour-sweet-corn
      outline: none

  .nav-dictionary
    li
      a
        &:focus
          outline: none
          box-shadow: 0 0 0 ($base-unit*0.3) $colour-sweet-corn
