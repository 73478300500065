.skip-content
  padding: $base-unit*1.5
  background-color: $colour-sweet-corn
  text-decoration: underline
  color: $colour-text
  left: -100%
  position: absolute

  &:focus
    display: block
    left: 0
    position: static
    text-decoration: underline
