@use "sass:math"

.contact
  margin-bottom: ($base-unit*2)

  .contact-title
    color: $colour-red
    font-family: $font-family-standard
    font-size: map-get($font-18, 'size')
    line-height: 1.25rem
    margin: 0 0 math.div($base-unit, 2) 0

  .list.list-contact
    font-family: $font-family-standard
    font-size: map-get($font-16, "size")
