$teaser-small-image-width: $base-unit*7
$teaser-image-width: $base-unit*17
$teaser-large-image-width: $base-unit*27
$teaser-larger-image-width: $base-unit*37
$teaser-key-width: $base-unit*2.5
$teaser-margin-bottom: $base-unit*2

.teaser
  @include clearfix()
  margin-bottom: $teaser-margin-bottom

  &-title
    font-family: $font-family-URW
    font-size: map-get($font-29, 'size')
    line-height: map-get($font-29, 'line-height')
    margin-bottom: 0

    a:after
      content: ''

  &-category
    font-family: $font-family-standard
    font-size: map-get($font-15, 'size')
    line-height: 1.25rem
    text-transform: none
    margin-bottom: 0

    a
      color: $colour-red

    .icon
      @include square(17px)
      margin-bottom: $base-unit*0.7

  &-body
    position: relative

    .teaser-date
      font-family: $font-family-standard
      font-size: map-get($font-15, 'size')
      line-height: map-get($font-15, 'line-height')
      margin-bottom: $base-unit*0.5
      color: $colour-tint-darker

  &--icon
    display: flex
    flex-direction: row

  &-icon
    padding: 0 $base-unit

    .icon
      +square($icon-32)

  &-footer
    font-size: map-get($font-15, 'size')
    margin: ($base-unit*0.5) 0 0

    li
      a
        text-decoration: none

        &:hover
          text-decoration: underline

    .icon
      opacity: 0.6
      color: $colour-link

  &.teaser-small
    margin-bottom: $base-unit*1.5

    .teaser-title
      font-family: $font-family-standard
      font-size: map-get($font-18, 'size')
      line-height: map-get($font-17, 'line-height')
      margin-bottom: $base-unit*0.2

    .teaser-body
      font-size: map-get($font-16, 'size')
      line-height: map-get($font-16, 'line-height')

      p
        a
          text-decoration: none

          &:hover
            text-decoration: underline

    .teaser-footer
      font-size: map-get($font-16, 'size')

    .teaser-date
      line-height: 1.25rem

  &.teaser-large
    border-top: $base-unit*0.3 solid $colour-tint-lightest
    border-bottom: $base-unit*0.3 solid $colour-tint-lightest
    margin: #{$base-unit*2} 0 #{$base-unit*3}
    padding: #{$base-unit*2} 0 #{$base-unit*3}

    .teaser-title
      font-family: $font-family-URW
      font-size: map-get($font-36, 'size')
      line-height: map-get($font-36, 'line-height')
      margin-bottom: $base-unit*0.5

    .teaser-body
      p
        font-family: $font-family-standard
        font-size: map-get($font-16, 'size')
        line-height: 1.25rem

      .teaser-category
        text-transform: none
        font-family: $font-family-standard
        font-size: map-get($font-15, 'size')
        line-height: 1.25rem

        .icon
          @include square(14px)
          fill: $colour-red

  &.with-image
    padding-left: ($teaser-image-width + ($base-unit*2))

    .teaser-image
      margin: 0
      margin-left: -($teaser-image-width + ($base-unit*2))
      float: left
      position: relative

      img
        @include box-shadow(0 0 $base-unit*0.2 rgba($colour-black, 0.2))

      a
        display: block

    &.teaser-small
      padding-left: ($teaser-small-image-width + $base-unit)

      .teaser-image
        margin-left: -($teaser-small-image-width + $base-unit)

    &.teaser-large
      padding-left: ($teaser-large-image-width + ($base-unit*2))

      .teaser-image
        margin-left: -($teaser-large-image-width + ($base-unit*2))

  &.teaser-vertical
    .teaser-title
      font-family: $font-family-URW
      font-size: map-get($font-26, 'size')
      line-height: map-get($font-26, 'line-height')
      margin-bottom: $base-unit*2

    .teaser-category
      font-family: $font-family-URW
      font-size: 0.875rem
      text-transform: uppercase

      .icon
        @include square(10px)
        margin: $base-unit*0.5 0 $base-unit*0.7 $base-unit*0.3
        fill: $colour-tapa

      a
        color: $colour-link
        text-decoration: none
        padding: $base-unit*0.8 $base-unit $base-unit*0.8 0

        &:hover
          text-decoration: underline

    &.with-image
      padding-left: 0
      display: flex
      flex-direction: column

      .teaser-image
        margin-left: 0
        margin-bottom: $base-unit
        float: none
        width: 100%

        img
          width: 100%

      &.teaser-small
        .teaser-title
          font-size: map-get($font-18, 'size')
          line-height: map-get($font-17, 'line-height')
          margin-bottom: $base-unit*0.2

  &.with-personalisation
    .teaser-body
      .teaser-category
        a
          color: $colour-genoa

    .teaser-image
      position: relative

  &.teaser-featured
    border-top: $base-unit*0.3 solid $colour-tint-lightest
    border-bottom: $base-unit*0.3 solid $colour-tint-lightest
    margin: #{$base-unit*2} 0 #{$base-unit*3}
    padding: #{$base-unit*2} 0 #{$base-unit*3}

    .teaser-footer
      .list-horizontal
        margin-bottom: 0

    .teaser-body
      .teaser-category
        text-transform: none
        font-family: $font-family-standard
        font-size: map-get($font-15, 'size')
        line-height: 1.25rem

        .icon
          @include square($icon-12)
          fill: $colour-red

  &.teaser-news
    .teaser-date
      margin: 0

    &.with-image
      padding-left: 0

      .teaser-image
        margin: 0
        float: right
        width: 100%

        img
          width: 100%

      &.teaser-small
        padding-left: ($teaser-small-image-width + $base-unit)

        .teaser-image
          margin: 0 0 0 #{-($teaser-small-image-width + $base-unit)}
          float: left
          width: $teaser-small-image-width

  &.teaser-event
    margin-bottom: $base-unit*0.5

    .event-meta
      margin-bottom: $base-unit
      margin-top: $base-unit*0.5

      .icon
        position: relative
        top: -2px

      li
        margin-bottom: $base-unit*0.5

  &.teaser-staff-event
    border-top: $base-unit*0.1 solid $colour-grey-lighter

    &:last-child
      border-bottom: $base-unit*0.1 solid $colour-grey-lighter

    .event-date
      margin: #{$base-unit*2.5} #{$base-unit*3} 0 0

    &.teaser-personalised
      .event-date,
      .teaser-body
        margin-top: $base-unit*1.3

      .school-container
        margin-bottom: 0

    .teaser-body
      display: inline-block
      vertical-align: top
      margin-top: $base-unit*2.5
      width: calc(100% - 80px)

      .teaser-title
        font-size: map-get($font-29, 'size')
        line-height: map-get($font-29, 'line-height')
        margin-bottom: 0
        font-family: $font-family-URW

      .list
        font-size: map-get($font-15, 'size')
        margin: $base-unit 0
        font-family: $font-family-standard

        li
          margin-bottom: $base-unit*0.4

        .icon
          margin-bottom: 0
          fill: $colour-grey-darker

        li:last-child
          p
            margin-bottom: 0

    .teaser-vertical
      .teaser-image
        width: ($grid-column-width*2) + $grid-gutter-width

  &.teaser-section
    .teaser-title
      font-size: map-get($font-28, 'size')
      line-height: map-get($font-28, 'line-height')
      font-family: $font-family-URW
      margin-bottom: $base-unit

    .teaser-body
      p
        color: $colour-grey-darker

    &:nth-child(even)
      float: right
      clear: right

    &:nth-child(odd)
      float: left
      clear: left

    .school-container
      margin-right: $base-unit*3.7

      &-text
        display: inline-block
        padding: #{$base-unit*0.3} $base-unit #{$base-unit*0.1}

  &.teaser-boxed
    border: $base-unit*0.1 solid $colour-grey-soft
    padding: $base-unit*2

    .teaser-title
      font-size: map-get($font-22, 'size')
      line-height: map-get($font-22, 'line-height')
      margin-bottom: $base-unit

    .teaser-body
      .teaser-category
        color: $colour-grey-darkest
        font-size: map-get($font-11, 'size')
        line-height: 1.25rem

      p
        margin-bottom: 0

  &.with-key
    &:before
      @include square($teaser-key-width)
      @include border-radius(100%)
      font-family: $font-family-bold
      content: attr(data-key)
      background: $colour-red
      color: $colour-white
      display: inline-block
      text-align: center
      vertical-align: middle
      font-size: map-get($font-11, 'size')
      line-height: 1.4375rem
      margin: 0
      float: left

    .teaser-body
      margin-left: ($teaser-key-width + $base-unit)

    + .teaser.with-key
      border-top: $base-unit*0.1 solid $colour-grey-light
      padding-top: $base-unit

    &[data-campus*='Heath']
      &:before
        background: $colour-btn-link

    &[data-campus*='Cathays']
      &:before
        background: $colour-red

    &[data-campus*='Hospital']
      &:before
        background: $colour-tahiti-gold

  &-personalised,
  &-news,
  &-section
    border-top: $base-unit*0.1 solid $colour-grey-lighter
    position: relative
    padding-top: $base-unit*2

    .school-container
      margin: (-$base-unit) 0 #{$base-unit*2} 0

  &.fill-height
    height: calc(100% - #{$teaser-margin-bottom})

.col-md-9
  .teaser
    &.with-image
      .teaser-image
        margin-bottom: $base-unit*3

@import 'teaser-md-only'
@import 'teaser-md-up'
@import 'teaser-lg-up'
