.nav.nav-tabs
  border-bottom: ($base-unit * 0.1) solid $colour-grey-light
  margin-left: 0

  .nav-item
    font-family: $font-family-standard
    margin: 0 ($base-unit * 0.3) (-$base-unit * 0.1) 0

    .nav-link
      @include border-radius(0)
      color: $colour-black
      margin-top: ($base-unit * 0.3)
      text-decoration: none
      padding: $base-unit*0.8 $base-unit*1.2

      &.active
        font-family: $font-family-bold
        outline: 0
        border-top-width: ($base-unit * 0.4)
        border-top-color: $colour-red
        margin: 0

      &:not(.active):hover
        border-top-width: ($base-unit * 0.4)
        background: $colour-grey-lighter
        margin: 0
        border-top-color: $colour-grey-light

    &:last-child
      margin-right: 0

.tabbable.tabs-left
  .nav
    &.nav-tabs
      border-bottom: none
      border-right: $base-unit*0.1 solid $colour-grey-light
      float: left
      margin-right: $base-unit*1.9

      .nav-item
        margin: #{($base-unit * 0.2)} #{(-$base-unit * 0.1)} 0 0

        &:first-child
          margin: 0 (-$base-unit*0.1) 0 0

        &:hover
          border-right: none
          border-left: none

          .nav-link
            border-top: none
            border-bottom: none

        .nav-link
          margin: 0 0 0 $base-unit*0.3

          &:hover
            border-left: $base-unit*0.4 solid $colour-grey-light

          &:hover,
          &.active
            margin: 0
            border-top: $base-unit*0.1 solid $colour-grey-light
            border-bottom: $base-unit*0.1 solid $colour-grey-light
            border-right: $base-unit*0.1 solid transparent

          &.active
            border-left: $base-unit*0.4 solid $colour-red

.tab-content
  max-width: 100%

  > .tab-pane
    display: block

.js-loaded
  .tab-content
    > .tab-pane
      display: none

      &.active
        display: block

@import 'tab-sm-up'
@import 'tab-md-up'
@import 'tab-lg-up'
