.fact
  text-align: center
  font-family: #{$franklin-gothic-URW}, #{$system-font}
  font-size: map-get($font-20, 'size')
  line-height: map-get($font-17, 'line-height')
  padding: #{($base-unit * 2.1)} 0 #{($base-unit * 1.9)} 0
  margin-bottom: ($base-unit*2)
  border-top: ($base-unit * 0.2) solid $colour-grey-lighter
  border-bottom: ($base-unit * 0.2) solid $colour-grey-lighter

  &-bold
    font-family: $font-family-standard
    font-size: map-get($font-29, 'size')
    line-height: map-get($font-17, 'line-height')
    display: block
    color: $colour-red

.fact-large
  .fact-bold
    display: block
    font-size: map-get($font-44, 'size')
    line-height: map-get($font-44, 'line-height')

.list-facts
  list-style: none
  margin-left: 0
  padding-left: 0

  .fact
    @include clearfix()
    margin-bottom: 0
    border-bottom: none
    border-top: none
    align-content: flex-start

    &:first-child
      margin-left: 0

@import 'fact-md-up'
