.r-staff-homepage
  main.content
    padding-top: 0
    padding-bottom: 0

  h2
    color: $colour-black

  .footer-global
    margin-top: 0
