$base-unit: 0.625rem

/* Core colours */
$colour-black: #22211f
$colour-true-black: #000000
$colour-red: #d4374a
$colour-green: #008458
$colour-link: #045bc6
$colour-white: #ffffff

$colour-link-visited: #4927a6
$colour-energy-yellow: #f8d349
$colour-link-hover: darken($colour-link, 20%)
$colour-cobalt: #054fa9

/* Supplementary colours */
$colour-ocean-green: #499f7f
$colour-algae-green: lighten($colour-ocean-green, 25%)
$colour-aero-blue: lighten($colour-ocean-green, 40%)

$colour-allports: #006aaf
$colour-malibu: lighten($colour-allports, 30%)
$colour-anakiwa: lighten($colour-allports, 60%)

$colour-affair: #73479c
$colour-lavender: lighten($colour-affair, 25%)
$colour-mauve: lighten($colour-affair, 50%)

$colour-cerise: #d63694
$colour-hot-pink: lighten($colour-cerise, 20%)
$colour-lavender-rose: lighten($colour-cerise, 45%)

/* Greys colours */
$colour-grey-darkest: #666666
$colour-grey-darker: #383735
$colour-grey-dark: #4e4e4c
$colour-grey: #555
$colour-grey-light: #d3d3d2
$colour-grey-soft: #dadada
$colour-grey-lighter: #e9e9e9
$colour-grey-lightest: #f0f0f0
$colour-wild-sand: #f6f6f6
$colour-grey-nickel: #c6c6c5
$colour-wild-sand-grey: #f5f5f5
$colour-gainsboro: #E6E6E6
$colour-cod-grey: #100F0F

/* Borders colours */
$colour-grey: $colour-grey
$border-width: 1px
$border-style: solid

/* Focus colours */
$colour-sweet-corn: #FCF199
$focus-box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn

/* Tints */
$colour-tint-prime: #91908f

$colour-tint-darkest: adjust-hue(darken($colour-tint-prime, 35%), 2)
$colour-tint-darker: adjust-hue(darken($colour-tint-prime, 17%), 1)
$colour-tint-dark: adjust-hue(darken($colour-tint-prime, 9%), 1)
$colour-tint-light: adjust-hue(lighten($colour-tint-prime, 17%), 1)
$colour-tint-lighter: adjust-hue(lighten($colour-tint-prime, 25%), 2)
$colour-tint-lightest: adjust-hue(lighten($colour-tint-prime, 35%), 2)

$colour-silver: #bbb
$colour-pickled-bluewood: #2b354c

/* Bootstrap primary changes */
$colour-alert-warning-bg: rgba(255, 209, 0, 0.2)

$colour-success: $colour-green
$colour-info: #3a6cb5

$colour-steel-blue: #537fb6
$colour-jade: #00b377

/* Buttons */
$colour-btn-bg: $colour-grey-lighter
$colour-btn-bg-gighlight: darken($colour-grey-lighter, 10%)
$colour-btn-border: $colour-silver

$colour-btn-primary-bg: $colour-red
$colour-btn-primary-bg-highlight: darken($colour-red, 10%)

$colour-btn-info-bg: #214fa3
$colour-btn-info-bg-highlight: darken($colour-btn-info-bg, 5%)

$colour-btn-success-bg: $colour-green
$colour-btn-success-bg-highlight: darken($colour-btn-success-bg, 5%)

$colour-btn-warning-bg: $colour-energy-yellow
$colour-btn-warning-bg-highlight: darken($colour-btn-warning-bg, 5%)

$colour-btn-danger-bg: $colour-grey-lighter
$colour-btn-danger-bg-highlight: darken($colour-red, 5%)

$colour-btn-inverse-bg: $colour-grey-light
$colour-btn-inverse-bg-highlight: $colour-grey-darker

$colour-btn-link: $colour-cobalt
$colour-btn-link-hover: $colour-btn-link
$colour-btn-link-bg: transparent
$colour-btn-link-bg-hover: transparent

$colour-text: $colour-grey-darker

$colour-alto: #d9d9d9
$colour-concrete: #f2f2f2
$colour-azure-radiance: #007bff
$colour-cararra: #ebe9e1

/* Content-group Colours */
$colour-husk: #bd9e5e
$colour-thunder: #231f20
$colour-yuma: #c8bb8d
$colour-haiti: #191333
$colour-bossanova: #3c2c59
$colour-atlantis: #8fc73e
$colour-bay-of-many: #233e8b
$colour-congress-blue: #034494

$colour-peppermint: #dff0d8
$colour-pearl-lusta: #fcf8e3
$colour-link-water: #d9edf7
$colour-polo-blue: #7598cb
$colour-green-haze: #008458
$colour-brick-red: #BA283A
$colour-mercury: #e4e4e4
$colour-transparent: rgba($colour-white, 0)
$colour-orange-peel: #FF9900
$colour-jungle-mist: #b2d4d7

/* Homepage colours */
$colour-mine-shaft: #333333
$colour-cabaret: #d94c5d

/* Global navigation */
$colour-regent-grey: #8a94a8
$colour-pickled-bluewood: #28354e
$colour-comet: #535d71

/* Local navigation */
$colour-local-navigation: #8a8a8a

/* Facet Colours */
$colour-tapa: #727270
$colour-dusty-grey: #999999

/* Form colours */
$colour-havelock-blue: #4786d5

/* Alert link button */
$colour-valid: #008458

/* Alert link button */
$colour-scotch-mist: #F0F0CC
$colour-orinoco: #CCCCAA
$colour-barley-white: #F8EEB8

/* Progress State */
$colour-progress-item-text-colour: #75757a
$colour-progress-item-bg-colour: #eae9e8
$colour-genoa: #198663
$colour-unpersonalised-grey: #8b8b8b

/* Personalised colours button, Icons and BG */
$colour-smoky: #5a556d

/* Pagination */
$colour-pagination-icon-hover: #0662d1

/* Teaser */
$colour-tahiti-gold: #f2860b

/* Progress bar */
$colour-success-tint: #468847
$colour-progress-bg: #f7f7f7

/* Apps Toolbar */
$colour-jagged-ice: #CDE8EA
$colour-boston-blue: #38A2AD
$colour-jelly-bean: #248A95
$colour-lochinvar: #277881
$colour-anakiwa-2: #96D6FF
$colour-apple: #69AB3D
$colour-mirage: #1C2236

/* Box Event */
$colour-science-blue: #0a5dc7
$colour-gallery: #efefef
$colour-ecstasy: #f58025

/* Map markers */
$colour-blaze-orange: #fe6b00
