@media #{$breakpoint-lg-up}
  .search-form
    button
      font-size: 1.3125rem
      width: 90px
      border-radius: 0

    &-container
      margin-right: $base-unit

    input[type="submit"]
      order: 2

    .search-form
      &-label
        .icon
          margin: 0 $base-unit

      &-query
        padding: $base-unit*0.8 $base-unit*4.3 $base-unit*0.8 $base-unit*4
        min-height: 40px
        order: 2

        &:focus
          outline: $base-unit*0.3 solid $colour-sweet-corn
          outline-offset: $base-unit*0.1
          box-shadow: none

      &-clear
        .icon
          fill: $colour-grey-darker

        &:focus
          background-color: $colour-sweet-corn
          box-shadow: none
          outline: none

          .icon
            fill: $colour-grey-darker

    &.with-border
      padding: $base-unit*4 0
