.media
  margin: $base-unit*1.5 0 $base-unit*2 0

  &.float,
  .float
    &-left
      margin-right: $base-unit

    &-right
      margin-left: $base-unit

  &-object
    display: block

  &-heading
    margin: 0 0 $base-unit*0.5

  &-list-small
    margin-left: 0
    list-style: none

    .media
      word-break: break-word

      .media-author
        font-family: $font-family-URW
        font-size: map-get($font-14, 'size')
        line-height: 1.25rem
        margin-bottom: 0
        margin-top: $base-unit*0.5

        .media-meta-date
          font-family: $font-family-standard
          font-size: map-get($font-13, 'size')
          line-height: map-get($font-17, 'normal')
          color: $colour-grey-darkest

      .media-reply
        margin-bottom: $base-unit
        margin-top: $base-unit*0.5

  &:first-child
    margin-top: 0

.media,
.media-body
  overflow: hidden
  zoom: 1

@import 'media-lg-up'
