.news
  &-title
    + .news-date
      margin-top: -($base-unit)

  &-date
    font-family: $font-family-URW
    font-size: 1.563rem
    line-height: 1.875rem
    color: $colour-tint-dark
