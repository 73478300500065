.search-form
  color: $colour-black

  form
    margin-bottom: 0

  .btn
    font-family: $font-family-bold

  &-container
    flex: 1 1 auto
    position: relative
    margin: 0 $base-unit 0 0
    display: flex

    .icon
      fill: $colour-tapa

  .has-input
    .search-form-clear
      display: flex
      visibility: visible

  .search-form
    &-label
      position: absolute
      left: 0
      top: 0
      bottom: 0
      display: flex
      align-items: center
      margin-bottom: 0

      .icon
        @include square(20px)
        margin: 0 $base-unit*1.5
        cursor: auto

    &-query
      font-size: map-get($font-17, 'size')
      line-height: map-get($font-20, 'line-height')
      font-family: $font-family-standard
      min-height: 50px
      padding: $base-unit*0.8 $base-unit*4.3 $base-unit*0.8 $base-unit*5
      outline: none
      width: 100%
      height: auto
      overflow: hidden

      &::-ms-clear
        display: none

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration
        display: none

      &::placeholder
        font-size: map-get($font-17, 'size')
        font-family: $font-family-standard
        color: $colour-silver

      &-container
        position: relative
        width: 100%

    &-clear
      position: absolute
      right: 0
      top: 0
      bottom: 0
      display: none
      align-items: center
      justify-content: center
      width: $base-unit*4.3
      margin: $base-unit*0.2 $base-unit*0.2 $base-unit*0.2 0
      border-top-right-radius: $base-unit*0.4
      border-bottom-right-radius: $base-unit*0.4

      .icon
        @include square($icon-10)
        vertical-align: baseline
        pointer-events: none
        margin-right: 0

        @import 'search-form-lg-up'
