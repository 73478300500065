@use "sass:math"

.nav-pills
  margin-left: 0
  margin-bottom: $base-unit*2.4
  list-style: none

  .nav-item
    line-height: 1.25rem

    .nav-link
      margin: ($base-unit*0.2) math.div($base-unit, 2) $base-unit*0.2 0
      border: ($base-unit * 0.1) solid (rgba($colour-btn-link, 0.25))
      text-decoration: none
      padding: $base-unit * 0.8 $base-unit * 1.2
      line-height: 0.875rem

      &:hover
        background: lighten($colour-btn-link,55%)

      &.active
        &:hover
          background: lighten($colour-btn-link, 15%)

@import 'pills-lg-up'
