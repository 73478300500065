// /* Import fonts
@use "sass:math"

+font-face($franklin-gothic-book, 'FranklinGothic-Book')
+font-face($franklin-gothic-book-it, 'FranklinGothic-BookIt')
+font-face($franklin-gothic-demi, 'FranklinGothic-Demi')
+font-face($franklin-gothic-med, 'FranklinGothic-Med')
+font-face($franklin-gothic-med-it, 'FranklinGothic-MedIt')
+font-face($franklin-gothic-URW, 'FranklinGothicURW-Lig')
+font-face($tee-franklin-gothic-thin, 'TeeFraThi')
+font-face($tee-franklin-gothic-light-obl, 'TeeFraLigObl')

body
  line-height: map-get($font-17, 'line-height')

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol
  margin-top: 0
  margin-bottom: $base-unit * 2
  text-shadow: none

html,
h1,
h2,
h3,
h4,
h5,
h6
  -webkit-font-smoothing: antialiased

h1
  font: normal 48px#{'/'}54px #{$franklin-gothic-URW}, #{$system-font}
  letter-spacing: -0.5px

h2
  font: normal 37px#{'/'}45px #{$franklin-gothic-URW}, #{$system-font}
  letter-spacing: -0.25px

h3
  font: normal 29px#{'/'}34px #{$franklin-gothic-URW}, #{$system-font}

h4
  font: normal 25px#{'/'}30px #{$franklin-gothic-URW}, #{$system-font}

h5
  font: normal 20px#{'/'}24px #{$franklin-gothic-URW}, #{$system-font}

h6
  font: normal normal 17px#{'/'}24px #{$franklin-gothic-URW}, #{$system-font}

h1, h2
  & + .lead
    margin-top: -$base-unit
    margin-bottom: $base-unit * 3

/* Link styling

.content,
.footer-local
  h1, h2, h3, h4, h5, h6
    a
      text-decoration: none

      &:hover
        text-decoration: underline

  p, li, td
    a
      text-decoration: underline

      &.btn
        text-decoration: none

.content
  a
    &:visited
      color: $colour-link-visited

a
  color: $colour-link
  text-decoration: none

  &:hover
    color: $colour-link-hover
    text-decoration: underline

  em
    font-family: $system-font
    text-decoration: none

i,
em
  font-style: normal
  font-family: $font-family-italic

b,
strong
  font-family: $font-family-bold

.brand
  font-family: $franklin-gothic-med, $system-font

.text-white
  color: $colour-white

.btn-block
  +border-radius(0)

  margin: #{$base-unit * 2} 0
  background-color: $colour-white

  &:hover
    background-color: $colour-grey-lighter

.btn-italic
  font-family: $system-font

.page-header
  border-bottom: 0 solid $colour-grey-lighter
  margin: 0
  padding: 0

  h1
    margin: #{$base-unit * 3} 0 #{$base-unit * 4} 0
    padding: 0

  h2
    margin: 0 0 $base-unit 0

  h3
    border-bottom: 1px solid #d0d0d0
    padding-bottom: #{$base-unit * 2}

.lead
  line-height: map-get($font-24, 'line-height')
  font-size: map-get($font-24, 'size')

.lead-small
  line-height: map-get($font-15, 'line-height')

.media
  .caption
    font-family: $font-family-standard
    background-color: $colour-grey-lighter
    padding: $base-unit

  em
    font-family: $font-family-standard

.smaller
  font-size: smaller

  > *
    line-height: 16px

dl
  dt
    dfn
      font-style: normal

  dd
    margin-bottom: #{math.div($base-unit, 2)}
    font-style: normal

abbr[title],
acronym[title]
  cursor: help
  border-bottom: 1px dotted $colour-tint-light

.hidden-text
  display: none
  font: 0 / 0

.colour-red
  color: $colour-red

h1, h2, h3, h4, h5, h6
  color: $colour-red

.page-parent
  @extend h1

  color: $colour-red

ul,
ol
  padding: 0
  margin: 0 0 $base-unit * 2 25px

ul ul,
ul ol,
ol ol,
ol ul
  margin-bottom: 0

li
  line-height: 24px

ul.unstyled,
ol.unstyled
  margin-left: 0
  list-style: none

ul.inline,
ol.inline
  margin-left: 0
  list-style: none

ul.inline > li,
ol.inline > li
  display: inline-block
