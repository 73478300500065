@media #{$breakpoint-md-up}
  .pagination
    .page-item
      .page-link
        padding: 0 ($base-unit*1.2)

        &:focus

          outline: #{$base-unit * 0.3} solid $colour-sweet-corn

      &:first-child,
      &:last-child
        .page-link
          padding: 0

          &:focus
            outline: none
            box-shadow: 0 0 0 ($base-unit * 0.3) $colour-sweet-corn
