@media #{$breakpoint-sm-up}
  .x-scroll
    &--full-width
      margin: 0 -#{$base-unit * 3}
      padding-bottom: ($base-unit*2)

      > div
        &:first-child
          margin-left: #{$base-unit * 3}

        &:last-child
          &::after
            content: ""
            width: 30px
            position: absolute
            left: 100%
            height: 1px