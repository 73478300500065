@use "sass:math"

$breakpoints-for-loop: ("sm": $breakpoint-sm-up, "md": $breakpoint-md-up, "lg": $breakpoint-lg-up, "xl": $breakpoint-xl-up)
$units: ("0": 0px, "5": 5px, "10": 10px, "15": 15px, "20": 20px, "30": 30px, "40": 40px)

=margin-padding-utilities-item($class, $value)
  .pl-#{$class}
    padding-left: $value !important

  .pr-#{$class}
    padding-right: $value !important

  .pt-#{$class}
    padding-top: $value !important

  .pb-#{$class}
    padding-bottom: $value !important

  .ml-#{$class}
    margin-left: $value !important

  .mr-#{$class}
    margin-right: $value !important

  .mt-#{$class}
    margin-top: $value !important

  .mb-#{$class}
    margin-bottom: $value !important

  .py-#{$class}
    padding-top: $value !important
    padding-bottom: $value !important

  .px-#{$class}
    padding-left: $value !important
    padding-right: $value !important

  .my-#{$class}
    margin-top: $value !important
    margin-bottom: $value !important

  .mx-#{$class}
    margin-left: $value !important
    margin-right: $value !important

=margin-padding-utilities-standard
  @each $class, $value in $units
    @include margin-padding-utilities-item($class, $value)

=margin-padding-utilities-breakpoints
  @each $class, $value in $units
    @each $breakpoint, $media in $breakpoints-for-loop
      @media #{$media}
        @include margin-padding-utilities-item('#{$breakpoint}-#{$class}', $value)

@function calc-rem($size)
  @return #{math.div($size, 16)}rem

@mixin font-face($style-name, $family)
  $filepath: '../webfonts/' + $family

  @if $style-name == $franklin-gothic-URW
    @font-face
      font-family: '#{$style-name}'
      src: url($filepath + '.woff') format('woff'), url($filepath + '.ttf') format('truetype')
      font-weight: 400
      font-display: swap
  @else
    @font-face
      font-family: '#{$style-name}'
      src: url($filepath + '.woff2') format('woff2'), url($filepath + '.woff') format('woff'), url($filepath + '.ttf') format('truetype')
      font-weight: 400
      font-display: swap

@mixin focus-state()
  background-color: $colour-sweet-corn
  text-decoration: none
  outline-offset: 0
  outline: 3px solid $colour-sweet-corn
  color: $colour-link

@mixin aspect-16-9()
  padding-top: 56.25%

@mixin bg-colour ($colour)
  background-color: #{$colour}

@mixin focus-box-shadow()
  outline: none
  border: none
  box-shadow: 0 0 0 #{$base-unit * 0.3} $colour-sweet-corn

%antialiasing
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

%transition
  transition: transform 0.15s ease-in

%rotate-0
  transform: rotate(0deg)

%rotate-90
  transform: rotate(90deg)

%rotate-180
  transform: rotate(180deg)

%rotate-270
  transform: rotate(270deg)

%no-border
  border: none

@mixin square($size)
  width: $size
  height: $size

@mixin flex($values)
  flex: $values

@mixin border-radius($radius: $border-radius)
  @if $enable-rounded
    border-radius: $radius

@mixin clearfix()
  &::after,
  &::before
    display: block
    clear: both
    content: ""

@mixin content-columns($column-count, $column-gap: $grid-gutter-width)
  column-count: $column-count
  column-gap: $column-gap

@mixin grid-ms-fallback($col, $row)
  -ms-grid-column: $col
  -ms-grid-row: $row

@mixin card-control-item()
  cursor: pointer
  opacity: 0

  .icon
    @include square(45px)
    background-color: $colour-grey-darker
    fill: $colour-white
    border-radius: 50%
    margin-right: 0

  &.disabled
    opacity: 0.5 !important
    cursor: not-allowed

  &.controls-item-prev,
  &.carousel-control-prev
    .icon
      padding: $base-unit * 1.2 $base-unit * 1.3 $base-unit * 1.2 $base-unit * 1.1

  &.controls-item-next,
  &.carousel-control-next
    .icon
      padding: $base-unit * 1.2 $base-unit * 1.1 $base-unit * 1.2 $base-unit * 1.3

  @media #{$breakpoint-lg-up}
    &:focus
      background-color: transparent
      outline: none

      &:not(.disabled)
        .icon
          box-shadow: 0 0 0 #{$base-unit * 0.3} $colour-sweet-corn

@mixin global-nav-active-arrow
  &:after,
  &:before
    bottom: 0
    content: ''
    display: block
    position: absolute
    width: 0
    height: 0
    border-style: solid
    z-index: 9999

  &:before
    left: calc(50% - 9px)
    border-color: $colour-transparent $colour-transparent $colour-grey-darkest $colour-transparent
    border-width: 8px

  &:after
    left: calc(50% - 8px)
    border-color: $colour-transparent $colour-transparent $colour-white $colour-transparent
    border-width: 7px

=local-nav-level-padding
  @for $i from 1 through 6
    .level-#{$i}
      padding-left: #{($i) * 18.5}px !important
      @if $i  == 2
        background-color: $colour-wild-sand

        &:hover
          color: $colour-grey-darkest
      @else
        background-color: $colour-white

// Reduces the antialiasing on Webkit and Firefox browsers by disabling sub-pixel rendering
@mixin fontantialiasing()
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

// mixin for use when adding intranet border to design elements
@mixin intranet-border($colour-grey)
  border-top: $base-unit*0.7 solid $colour-grey

//button build mixin
@mixin btn-build-lighten($btn-base, $btn-text-colour, $btn-icon-colour, $btn-lighten:15%)
  background: $btn-base
  color: $btn-text-colour

  .icon
    fill: $btn-icon-colour

  .icon
    fill: $btn-icon-colour
    background: $colour-transparent

  &:hover,
  &:focus,
  &:active
    background: darken($btn-base, $btn-lighten)

  &:visited
    color: $btn-text-colour

@mixin btn-build-darken($btn-base, $btn-text-colour, $btn-icon-colour, $btn-darken)
  background: $btn-base
  color: $btn-text-colour

  .icon
    fill: $btn-icon-colour

  .icon
    fill: $btn-icon-colour
    background: $colour-transparent

  &:hover,
  &:focus,
  &:active
    background: darken($btn-base, $btn-darken)
    color: $btn-text-colour

  &:visited
    color: $btn-text-colour

@mixin indentation($size)
  text-indent: calc((#{$size})* -1)
  padding-left: calc(#{$size})
