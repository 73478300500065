.personalised-school-container,
.school-container
  margin-bottom: $base-unit*3

  &-text
    background-color: $colour-genoa
    color: $colour-white
    font-size: map-get($font-15, 'size')
    text-transform: uppercase

.personalised
  &-standardpage-container
    position: relative
    margin-top: -$base-unit*2

    .icon-personalisation
      top: -20px
      right: 0

      .icon
        width: 48px
        height: 56px

    .personalised-school-container
      margin-right: $base-unit*6.3

  &-content-container
    position: relative
    padding: $base-unit $base-unit*2 $base-unit
    border: $base-unit*0.1 solid $colour-grey-soft
    margin-bottom: $base-unit*3

    &:last-child
      margin-bottom: 0

    .school-container
      margin: 0 0 $base-unit*3 #{-$base-unit}

  &-search-container
    .icon-personalisation
      margin-top: -$base-unit

  &-school
    &-container
      margin: $base-unit 0 $base-unit*3
      border-top: $base-unit*0.1 solid $colour-grey-lighter
      position: relative

      &-text
        padding: $base-unit*0.5 $base-unit*1.5
        margin-top: $base-unit
        display: inline-block

    &-search-container
      margin: -$base-unit*0.5 0 $base-unit 0

.school-container-text
  padding: $base-unit*0.7 $base-unit*1.1 $base-unit*0.5

.personalised-block,
.unpersonalised-block
  border: $base-unit*0.1 solid $colour-grey-soft
  position: relative
  padding: $base-unit*2

  .school-container
    position: relative
    top: -8px
    left: -10px

.unpersonalised-block
  .icon-personalisation
    polygon
      fill: $colour-unpersonalised-grey

  .school-container
    &-text
      background: $colour-unpersonalised-grey

@import 'personalised-md-up'
