.bg
  &-wild-sand
    background-color: $colour-wild-sand !important

  &-jungle-mist
    background-color: $colour-jungle-mist !important

    a
      color: darken($colour-link, 10%)

  &-red
    background-color: $colour-red !important
