@media #{$breakpoint-md-only}
  .homepage-events-wrapper
    .box-event
      width: calc(50% - 12px)
      flex-basis: 44.5%
      flex-basis: calc(50% - 10px)
      -ms-flex-preferred-size: 43.5%
      margin-bottom: $base-unit*2

      &:nth-child(2),
      &:last-child
        margin-right: 0
        
      &:nth-child(3),
      &:last-child
        margin-bottom: 0