@media #{$breakpoint-md-up} 
  .process 
    &-steps
      display: flex
      justify-content: space-between

    &-step
      display: flex
      flex: 1
      width: 100% 

      &-title
        padding: $base-unit * 1.5 $base-unit * 2.25 $base-unit * 1.5 $base-unit * 4.5
        
        &:after
          display: none
          
        svg
          display: block    

      &:first-child
        margin-left: 0

        .process-step-title
          width: 100%
          padding: $base-unit * 1.5 $base-unit * 2.25

      &:last-child
        margin-right: 0

        .process-step-title svg
          display: none