@use "sass:math"

$inputWidth: 206px

@media #{$breakpoint-md-up}
  form
    &.form
      &-inline
        .form-control
          &.search-query
            padding-right: $base-unit

        label,
        #query
          width: auto

        .control:not([class*="col"])
          width: $inputWidth

    select,
    select.form-control,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"]
      width: $inputWidth

    input[class*="col"],
    select[class*="col"]
      width: 100%

    input[type="search"]
      padding-right: $base-unit

    &.form-horizontal
      .form-group,
      .control-group
        .control-label
          float: left
          width: $base-unit*16
          padding-top: math.div($base-unit, 2)
          text-align: right

      .controls
        margin-left: $base-unit*18

    .form-group
      .col-form-label
        margin-right: -$base-unit*1.8
        text-align: right
        padding: math.div($base-unit, 2) $base-unit*2.2 0 0

    .sq-form
      &-question
        padding-left: $base-unit*18
        margin-bottom: $base-unit*2

        &-title
          margin-left: -$base-unit*18
          float: left
          text-align: right
          width: $base-unit*16
          padding-top: math.div($base-unit, 2)

        &-note
          font-style: normal
          margin-bottom: $base-unit
          display: block
          padding-top: math.div($base-unit, 2)

  .form-control,
  input,
  textarea,
  select
    &:focus
      box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn
      border-color: $colour-grey-darker
      outline: 0
