@media #{$breakpoint-md-only}
  .teaser
    &.teaser-vertical
      &.with-image
        flex-direction: row

        .teaser-image
          flex-basis: 226px

        .teaser-body
          flex-basis: 476px
          margin-left: $base-unit*2     