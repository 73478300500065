.nav-breadcrumb
  grid-area: breadcrumb
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  order: 3
  border-bottom: #{$base-unit * 0.1} solid $colour-grey-soft

  .breadcrumb
    @extend .flex-nowrap
    @extend .align-items-center
    margin: 0

    &-item
      @extend .text-truncate
      font-family: $font-family-URW
      margin-left: $base-unit
      /* Match size to avoid any height issues */
      line-height: map-get($font-17, 'size')

      a
        color: $colour-link
        text-decoration: underline

        &:hover
          color: $colour-tint-dark

      &:first-child
        margin-left: 0

      &-separator
        margin: 0 0 0 $base-unit

        &:first-child
          margin-left: 0

        .icon
          @include square($icon-12)
          display: block
          fill: $colour-tapa
          margin: 0

      &.d-none.d-lg-block
        & + .breadcrumb-item
          &:not(.d-none.d-lg-block)
            margin-left: 0
            padding-left: 0

@import 'breadcrumb-lg-up'
