@media #{$breakpoint-sm-up}
  .header-global
    &-control
      width: 90px

    &-brand
      font-size: 1.625rem
      padding: $base-unit*2 $base-unit*1.5

      .icon
        margin-right: $base-unit*1.5