.search
  &-results
    .profile
      padding: 0 $base-unit*1.7 $base-unit*1.7

    .teaser
      padding: $base-unit*1.7
      border-top: $base-unit*0.1 solid $colour-grey-lighter

      &-body
        p
          margin-bottom: $base-unit*0.5
          word-wrap: break-word

          a
            text-decoration: none

      &:last-of-type
        border-bottom: $base-unit*0.1 solid $colour-grey-lighter

      &.best-bet
        background: $colour-link-water
        padding: 0 $base-unit*1.7 $base-unit*1.7 $base-unit*1.7

        .teaser-body
          h1
            &.teaser-title
              margin-top: $base-unit*1.5

      &.teaser-curation
        border: solid $base-unit*0.2 $colour-gallery
        padding: $base-unit*1.5

    .search-result-url
      color: $colour-grey-darkest

      &:hover
        color: $colour-link-hover

    &-count
      font-size: 0.875rem
      font-family: $font-family-standard

    .accordion
      .accordion-toggle
        font-family: $font-family-standard
        font-size: 1.6875rem
        line-height: map-get($font-34, 'line-height')
        color: $colour-red
        padding: 0

        .icon
          @include square($icon-24)
          fill: $colour-red
          margin: 0 0 0 $base-unit

      .accordion-inner
        padding: 0

  &-facet
    margin-bottom: $base-unit*2

    h4
      cursor: pointer
      margin-bottom: $base-unit

    ul
      padding: 0
      margin: 0
      float: left
      width: 100%

      li
        margin: 0
        padding: 0
        list-style: none
        width: 100%
        float: left

        a
          margin: 0
          list-style: none
          width: auto
          border-bottom: solid $base-unit*0.1 $colour-white
          padding: $base-unit
          display: block
          text-decoration: none

          &:hover
            background: $colour-silver

        &.selected
          background: $colour-mercury

          a
            background: $colour-mercury

            &:hover
              background: $colour-white

        .icon
          @include square($icon-20)
          float: left
          margin: $base-unit*0.4 $base-unit 0 0

          @import 'search-lg-up'
