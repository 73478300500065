@media #{$breakpoint-lg-up}
  .header-global
    background-color: $colour-white

    a
      color: $colour-grey-darker

      &:focus
        box-shadow: $focus-box-shadow
        background-color: $colour-sweet-corn

    &-container
      position: relative
      max-width: map-get($container-max-widths, "lg")
      margin: 0 auto
      align-items: center
      height: 110px
      padding: 0 $base-unit*1.5

      .search
        &-form
          display: block

          &-clear
            &:focus
              box-shadow: 0 0 0 0 $colour-sweet-corn

          &-container
            width: 364px

      .dropdown
        &-menu
          margin-top: $base-unit
          border: $base-unit*0.1 solid $colour-dusty-grey
          border-radius: 0
          min-width: 135px
          padding: 0

          &:before
            content: ''
            display: inline-block
            border-left: $base-unit*0.7 solid transparent
            border-right: $base-unit*0.7 solid transparent
            border-bottom: $base-unit*0.7 solid $colour-dusty-grey
            position: absolute
            top: -7px
            left: 5px

          &:after
            content: ''
            display: inline-block
            border-left: $base-unit*0.6 solid transparent
            border-right: $base-unit*0.6 solid transparent
            border-bottom: $base-unit*0.6 solid $colour-white
            position: absolute
            top: -6px
            left: 6px

        &-item
          line-height: map-get($font-17, 'line-height')
          font-size: map-get($font-15, 'size')
          margin: $base-unit*0.5
          padding: $base-unit*0.5 0 $base-unit*0.5 $base-unit*1.5
          color: $colour-grey-darker
          background-color: $colour-transparent
          display: block
          width: auto

    &-brand
      padding: 0
      font-size: 1.875rem

      &:hover
        color: $colour-grey-darker

      .icon
        margin-right: $base-unit

    &-user,
    &-context
      .icon:first-of-type
        @include square(20)

      &:hover
        text-decoration: none

    &-user
      position: absolute
      right: 130px
      top: 43px

      .icon
        fill: $colour-silver

        &:last-of-type
          @include square($icon-10)
          fill: $colour-grey-darker
          margin-bottom: $base-unit*0.2

      &-name
        max-width: 100px
        text-overflow: ellipsis
        display: inline-block
        white-space: nowrap
        overflow-x: hidden
        vertical-align: bottom
        margin-right: $base-unit*0.5

    &-context
      text-decoration: none
      font-size: 1.125rem
