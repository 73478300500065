@media #{$breakpoint-lg-up}
  .teaser
    &.with-image
      a
        &:focus
          img
            box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn

    .teaser-body
      .teaser-category
        a
          &:focus
            text-decoration: none

      p
        a
          &:focus
            background-color: $colour-sweet-corn
            box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn

    .teaser-title
      a
        &:focus
          background-color: $colour-sweet-corn
          box-shadow: 0 0 0 $base-unit*0.3 $colour-sweet-corn
          text-decoration: none

    &.teaser-news
      &.with-image
        padding-right: $base-unit*19

        .teaser-image
          margin: 0 (-$base-unit*19) 0 0
          width: 170px

      &.teaser-small
        &.with-image
          padding-right: 0

    &.teaser-section
      .teaser-title
        font-size: map-get($font-35, 'size')
        line-height: map-get($font-35, 'line-height')
