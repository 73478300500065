.badge,
.label
  background: $colour-grey-light
  font-family: #{$franklin-gothic-med}, #{$system-font}
  font-size: map-get($font-15, "smaller")
  line-height: map-get($font-15, "line-height")
  font-weight: normal
  text-shadow: none
  padding: 0 ($base-unit*0.6)
  color: $colour-black
  border-radius: 0.563rem

  &-success, &-info, &-important, &-inverse
    color: $colour-white

  &-success
    background: $colour-green

  &-info
    background: $colour-btn-info-bg

  &-warning
    background: $colour-energy-yellow
    color: $colour-black

  &-important
    background: $colour-red

  &-inverse
    background: $colour-black

  .icon
    +square($base-unit)
    margin-right: 0
    margin-bottom: $base-unit*0.3

.label
  @extend .badge
  border-radius: $base-unit*0.2
