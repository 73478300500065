@use "sass:math"
$btn-arrow-size: $base-unit*4.3

.btn
  @include border-radius(math.div($base-unit, 2))
  color: $colour-grey-darker
  border: none
  background: $colour-grey-light
  text-shadow: none
  font-family: $font-family-standard
  font-size: map-get($font-16, 'size')
  line-height: 1.188rem
  padding-top: ($base-unit*1.2)
  padding-bottom: ($base-unit*1.2)
  user-select: text

  .icon
    @include square($base-unit*1.6)
    margin-left: (-($base-unit*0.2))
    fill: $colour-tint-darkest
    display: inline

  &:hover .icon
    text-decoration: none
    background: $colour-transparent

  &.small
    font-family: $font-family-standard
    text-transform: none
    font-size: map-get($font-14, 'size')

  &.active,
  &:active
    @include box-shadow(none)

  &-outline
    color: $colour-black
    background-color: $colour-transparent
    background-image: none
    border: solid #{$base-unit * 0.1} $colour-grey

    &:focus,
    &:active,
    &:hover,
    &.active
      background-color: $colour-transparent

    &.disabled
      &:focus,
      &.focus
        border-color: $colour-grey-light

    &-link
      border: ($base-unit*0.1) solid $colour-btn-link-hover
      color: $colour-btn-link
      background-color: $colour-btn-link-bg

      &:hover,
      &:focus
        background-color: $colour-btn-link-bg

  &.btn
    &-success,
    &-info,
    &-error,
    &-primary,
    &-warning,
    &-link
      @include fontantialiasing()

    &-info,
    &-primary
      @include btn-build-darken($colour-btn-link, $colour-white, $colour-white, 10%)

    &-success
      @include btn-build-darken($colour-green-haze, $colour-white, $colour-white, 10%)

    &-error
      @include btn-build-darken($colour-red, $colour-white, $colour-white, 10%)

    &-warning
      @include btn-build-darken($colour-energy-yellow, $colour-grey-darker, $colour-grey-darker, 10%)

    &-link
      @include btn-build-lighten($colour-transparent, $colour-link, $colour-red, 0%)

    &-arrow
      position: relative
      margin-right: $base-unit*2
      border-radius: 0

      &:before,
      &:after
        content: ' '
        height: 0
        position: absolute
        z-index: 1
        top: 0
        width: 0

        right: -#{math.div($btn-arrow-size, 2)}
        border: math.div($btn-arrow-size, 2) solid transparent
        border-left-color: $colour-grey-light
        transition: border-color .15s ease-in-out

      &:hover,
      &:focus,
      &:active
        background-color: $colour-mercury
        color: $colour-grey-dark

      &:after
        border-left-color: $colour-grey-light
        border-right: none

      &:before
        border-left: none

      &:focus
        background-color: $colour-sweet-corn
        outline: none
        box-shadow: none

      &:hover
        &:before,
        &::after
          border-left-color: $colour-mercury

      &:focus
        &:before,
        &::after
          border-left-color: $colour-sweet-corn

      &-left
        margin-left: math.div($btn-arrow-size, 2)
        margin-right: 0

        &:before,
        &:after
          left: -#{math.div($btn-arrow-size, 2)}
          right: 0

        &:after
          border: none

        &:before
          border-right-color: $colour-grey-light
          border-left: none

        &:hover
          &:before
            border-right-color: $colour-mercury
            border-left: none

        &:focus
          &:before
            border-right-color: $colour-sweet-corn
            border-left: none

    &-sm
      padding: $base-unit*0.2 $base-unit $base-unit*0.1 $base-unit
      font-size: map-get($font-14, 'size')

    &-mini
      padding-top: 0
      padding-bottom: 0

    &-xs
      padding-top: 0
      padding-bottom: 0

    &-lg,
    &-large
      &.btn-arrow
        &:before
          left: -#{math.div($btn-arrow-size, 2)}

        &:after
          right: -#{math.div($btn-arrow-size, 2)}

  &-group
    &-title
      + .btn
        @include  border-radius($base-unit*0.3 0 0 $base-unit*0.3)
        font-size: map-get($font-24, 'size')
        font-family: $font-family-standard

    .btn:first-child
      @include  border-radius($base-unit*0.3 0 0 $base-unit*0.3)

    .btn:last-child
      @include border-radius(0 $base-unit*0.3 $base-unit*0.3 0)

    .btn
      @include border-radius(0)
      border: 1px solid lighten($colour-btn-link, 25%)
      background: rgba($colour-white, 0.8)
      padding-left: $base-unit*2.1
      padding-right: $base-unit*2

      &:hover
        background: lighten($colour-btn-link, 55%)

      &.active
        background-color: $colour-btn-link
        color: $colour-white

        &:hover
          background-color: lighten($colour-btn-link, 15%)

    &.full-width
      display: flex
      flex-wrap: wrap

      .btn
        display: block
        width: 100%
        min-height: $base-unit*5
        line-height: $base-unit*5
        background: $colour-grey-lightest
        border: $border-width solid $colour-grey-soft

        .title
          float: left
          margin: 0
          word-break: break-word
          white-space: normal
          line-height: map-get($font-24, 'line-height')
          font-family: $font-family-URW
          font-size: map-get($font-24, 'size')
          color: $colour-red

        .icon
          float: right
          line-height: $base-unit*5
          fill: $colour-grey-darkest

        &:first-child
          @include border-radius($base-unit $base-unit 0 0)

        &.btn + .btn
          margin: 0
          border-top: none

        &:last-child
          @include border-radius(0 0 $base-unit $base-unit)

        &:hover
          background: $colour-grey-lighter
          text-decoration: none

        &:hover .icon
          background: inherit

  &-more
    font-size: map-get($font-17, 'size')
    line-height: map-get($font-17, 'line-height')
    background: $colour-transparent
    color: $colour-link
    border-radius: 0

    &:hover
      text-decoration: underline

    .icon
      @include square($icon-12)
      margin: 0 0 0 $base-unit*0.5

  &-inverse
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.disabled,
    &[disabled]
      color: $colour-white
      background-color: $colour-grey-darker

  &-back
    color: $colour-grey-darker
    display: inline-block
    cursor: pointer
    padding: $base-unit
    margin: #{-$base-unit} 0 $base-unit*4 #{-$base-unit}

    .icon
      color: $colour-grey-darker
      margin-bottom: $base-unit*0.3

    &:hover
      color: $colour-grey-darker

@import 'button-md-up'
@import 'button-lg-up'
