.fill
  &-pickled-bluewood
    fill: $colour-pickled-bluewood !important

  &-blaze-orange
    fill: $colour-blaze-orange !important

  &-red
    fill: $colour-red !important

  &-green
    fill: $colour-green !important