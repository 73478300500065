.video 
  margin: 0 0 $base-unit*2
  max-width: 100%
  max-height: 100%

  iframe 
    @include square(100%)
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    border: none
    
  .video-body 
    @include aspect-16-9()
    width: 100%
    height: 0
    position: relative