.pagination
  @extend .justify-content-center
  @include box-shadow(none)
  margin: ($base-unit*2.4) 0
  flex-wrap: wrap

  .icon
    @include square($base-unit*3)
    padding: ($base-unit*0.7)
    fill: $colour-grey-light
    background: $colour-grey-lighter
    margin-right: 0

  .page-item
    display: none

    &.active,
    &:first-child,
    &:last-child
      display: inline

    &:first-child,
    &:last-child
      .page-link
        @include border-radius(0)
        padding: 0

        &:hover
          background: $colour-transparent

        .icon
          margin-right: 0

          &:hover
            background: $colour-pagination-icon-hover

    &:first-child
      margin-right: ($base-unit*2)

    &:last-child
      margin-left: ($base-unit*2)

      .page-link
        .icon
          margin-left: 0

    &.active .page-link
      color: $colour-white

      &:focus
        background-color: $colour-black
        color: $colour-white

    &.disabled
      .page-link
        background-color: $colour-grey-lighter

        .icon
          fill: $colour-grey-lighter

    > .page-link:hover
      color: $colour-grey-darker
      background-color: $colour-grey-light

    .page-link
      text-decoration: none
      margin-left: 0

      .icon
        fill: $colour-white
        background-color: $colour-btn-link

@import 'pagination-xs-up'
@import 'pagination-md-up'
@import 'pagination-lg-up'
